@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Keyboard
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-keyboard"

#{$block}
  display: block
  width: 100%

  // $Keyboard MOD: role message
  &_role_message

  // $Keyboard MOD: role search
  &_role_search


  // $Keyboard controls
  &__controls
    display: block
    width: 100%


  // $Keyboard controls row
  &__controls-row
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%

    &:not(:last-child)
      margin-bottom: 10px

      +media-screen-1280x800
        margin-bottom: 9px

      +media-screen-1024x768
        margin-bottom: 8px

      +media-screen-1024x600
        margin-bottom: 6px

    &:last-child
      margin-top: 20px

      +media-screen-1280x800
        margin-bottom: 18px

      +media-screen-1024x768
        margin-top: 16px

      +media-screen-1024x600
        margin-top: 12px


  // $Keyboard control
  &__control
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 63px
    height: 63px
    margin: 0
    padding: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 42px
    line-height: 63px
    font-weight: 500
    color: $c-keyboard-control-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-keyboard-control-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      width: 48px
      height: 48px
      font-size: 33px
      line-height: 48px
      border-radius: 9px

    +media-screen-1024x768
      width: 46px
      height: 46px
      font-size: 30px
      line-height: 46px
      border-radius: 9px

    +media-screen-1024x600
      width: 35px
      height: 35px
      font-size: 24px
      line-height: 35px
      border-radius: 7px

    &:hover
      background-color: $c-keyboard-control-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 10px

      +media-screen-1280x800
        margin-right: 9px

      +media-screen-1024x768
        margin-right: 8px

      +media-screen-1024x600
        margin-right: 6px

    // $Keyboard control MOD: role shift
    // $Keyboard control MOD: role backspace
    &_role_shift,
    &_role_backspace
      width: 112px
      font-size: 0
      line-height: 0
      color: transparent

      +media-screen-1024x768
        width: 89px

      +media-screen-1024x600
        width: 89px

      &:before
        content: ''
        display: inline-block
        font-family: icons
        color: $c-keyboard-control-icon

    &_role_shift
      &:before
        content: '\e918'
        font-size: 30px
        line-height: 30px

        +media-screen-1280x800
          font-size: 24px
          line-height: 24px

        +media-screen-1024x768
          font-size: 22px
          line-height: 22px

        +media-screen-1024x600
          font-size: 18px
          line-height: 18px

    &_role_backspace
      &:before
        content: '\e917'
        font-size: 42px
        line-height: 30px

        +media-screen-1280x800
          font-size: 34px
          line-height: 26px

        +media-screen-1024x768
          font-size: 30px
          line-height: 24px

        +media-screen-1024x600
          font-size: 24px
          line-height: 18px

    // $Keyboard control MOD: role space
    &_role_space
      width: 453px

      +media-screen-1024x600
        width: 362px



