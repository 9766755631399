.pos-body
  background-color: unset

body
  background-color: #fffce6

.pos-menu__subcategories-row
  transform: translate(0px, 0px) translateZ(0px)

.pos-sidebar__menu-item:not(:last-child)
  margin-bottom: 10px !important

.waiter-id-button
  line-height: 34px !important
  height: 34px !important
  border: none !important

.pos-pin-entry-devices__empty
  display: block
  padding-top: 13px
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif
  font-size: 24px
  line-height: 61px
  font-weight: 700
  color: #9f0101
  text-align: center
  text-transform: uppercase

.pos-data_role_sale .pos-data__item:not(:last-child)
  margin-bottom: 30px !important

.pos-transaction-adding-warning
  position: absolute
  bottom: 0
  left: calc((100% - 500px) / 2)
  text-align: center
  width: 500px
  background-color: red
  z-index: 999999
  color: #fff
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif

.offline-card
  background-color: #a36969
  font-size: 24px

.offline-card:disabled
  opacity: 0.5

.offline-card:hover
  background-color: darken(#a36969, 20%)

.ped-unavailable
  background-color: #a36969
  