@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Datepicker
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-datepicker"

#{$block}
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  width: 100%
  margin: 0 auto

  // $Datepicker MOD: role bookings
  &_role_bookings
    flex-grow: 1
    flex-shrink: 1


  // $Datepicker init input
  &__init-input,
  &__init-input[hidden]
    display: none


  // $Datepicker calendar
  &__calendar
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 100%

    &:not(:last-child)
      margin-bottom: 20px
      padding-bottom: 10px
      border-bottom: 1px solid $c-datepicker-calendar-border

      +media-screen-1280x800
        margin-bottom: 16px
        padding-bottom: 8px

      +media-screen-1024x768
        margin-bottom: 15px
        padding-bottom: 7px

      +media-screen-1024x600
        margin-bottom: 12px
        padding-bottom: 6px


  // $Datepicker preview
  &__preview
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    margin-bottom: 20px

    +media-screen-1280x800
      margin-bottom: 16px

    +media-screen-1024x768
      margin-bottom: 15px

    +media-screen-1024x600
      margin-bottom: 12px


  // $Datepicker preview text
  &__preview-text
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 30px
    line-height: 30px
    font-weight: 700
    color: $c-datepicker-preview-text
    text-align: center

    +media-screen-1280x800
      font-size: 24px
      line-height: 24px

    +media-screen-1024x768
      font-size: 22px
      line-height: 22px

    +media-screen-1024x600
      font-size: 18px
      line-height: 18px


  // $Datepicker actions
  &__actions
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%


  // $Datepicker action
  &__action
    flex-grow: 0
    flex-shrink: 0
    display: inline-block
    width: 195px
    height: 65px
    padding: 0
    border-radius: 12px
    font-family: $f-sans
    font-size: 21px
    line-height: 65px
    font-weight: 700
    color: $c-datepicker-action-text
    background-color: $c-datepicker-action-bg
    text-align: center
    text-transform: uppercase
    text-decoration: none
    outline: none
    cursor: pointer
    border: none
    transition: all .3s

    +media-screen-1280x800
      height: 49px
      font-size: 18px
      line-height: 49px
      border-radius: 9px

    +media-screen-1024x768
      width: 156px
      height: 46px
      font-size: 16px
      line-height: 46px
      border-radius: 9px

    +media-screen-1024x600
      width: 156px
      height: 40px
      font-size: 15px
      line-height: 40px
      border-radius: 7px

    &:hover
      background-color: $c-datepicker-action-hover-bg

    &:focus
      outline: none

    // $Datepicker action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    &:not(:last-child)
      margin-right: 70px

      +media-screen-1024x768
        margin-right: 56px

      +media-screen-1024x600
        margin-right: 56px

    // $Datepicker action MOD: role cancel
    &_role_cancel
      background-color: $c-datepicker-action-cancel-bg
      color: $c-datepicker-action-cancel-text

      &:hover
        background-color: $c-datepicker-action-cancel-hover-bg

    // $Datepicker action MOD: role confirm
    &_role_confirm
      background-color: $c-datepicker-action-confirm-bg
      color: $c-datepicker-action-confirm-text

      &:hover
        background-color: $c-datepicker-action-confirm-hover-bg


  // Vendor styles overrides
  & .flatpickr-calendar
    width: 100%
    font-family: $f-sans
    box-shadow: none
    border-radius: 0
    background-color: $c-datepicker-bg

  & .flatpickr-months
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-bottom: 10px
    padding-bottom: 20px
    border-bottom: 1px solid $c-datepicker-month-border

    +media-screen-1280x800
      margin-bottom: 8px
      padding-bottom: 16px

    +media-screen-1024x768
      margin-bottom: 7px
      padding-bottom: 15px

    +media-screen-1024x600
      margin-bottom: 6px
      padding-bottom: 12px

  & .flatpickr-innerContainer
    width: 100%

  & .flatpickr-rContainer
    width: 100%

  & .flatpickr-months .flatpickr-prev-month,
  & .flatpickr-months .flatpickr-next-month
    width: 80px
    height: 40px
    padding: 0
    border-radius: 12px
    border: 2px solid $c-datepicker-month-control-border
    background-color: $c-datepicker-month-control-bg
    text-align: center
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 35px
      border-radius: 9px

    +media-screen-1024x768
      width: 64px
      height: 35px
      border-radius: 9px

    +media-screen-1024x600
      width: 64px
      height: 30px
      border-radius: 7px

    &:before
      content: ''
      display: inline-block
      font-family: $f-sans
      font-size: 36px
      line-height: 34px
      color: $c-datepicker-month-control-icon
      transition: all .3s

      +media-screen-1280x800
        font-size: 30px
        line-height: 30px

      +media-screen-1024x768
        font-size: 30px
        line-height: 30px

      +media-screen-1024x600
        font-size: 26px
        line-height: 26px

    &:hover
      background-color: $c-datepicker-month-control-hover-bg

      &:before
        color: $c-datepicker-month-control-hover-icon

  & .flatpickr-months .flatpickr-prev-month
    &:before
      content: '\003C'

  & .flatpickr-months .flatpickr-next-month
    &:before
      content: '\003E'

  & .flatpickr-months .flatpickr-prev-month svg,
  & .flatpickr-months .flatpickr-next-month svg
    display: none

  & .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time
    height: 50px
    max-height: 50px
    margin-top: 10px
    padding-top: 10px
    line-height: 40px
    border-color: $c-datepicker-time-border

    +media-screen-1280x800
      height: 42px
      max-height: 42px
      margin-top: 7px
      padding-top: 7px
      line-height: 35px

    +media-screen-1024x768
      height: 42px
      max-height: 42px
      margin-top: 7px
      padding-top: 7px
      line-height: 35px

    +media-screen-1024x600
      height: 36px
      max-height: 36px
      margin-top: 6px
      padding-top: 6px
      line-height: 30px

  & .flatpickr-calendar.showTimeInput.hasTime.noCalendar .flatpickr-time
    height: 40px
    max-height: 40px
    margin-top: 0
    padding-top: 0
    border: none

    +media-screen-1280x800
      height: 35px
      max-height: 35px

    +media-screen-1024x768
      height: 35px
      max-height: 35px

    +media-screen-1024x600
      height: 30px
      max-height: 30px

  & .flatpickr-calendar.showTimeInput.hasTime .flatpickr-am-pm,
  & .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time-separator,
  & .flatpickr-time input.flatpickr-hour,
  & .flatpickr-time input.flatpickr-minute,
  & .flatpickr-time input.flatpickr-second
    font-family: $f-sans
    font-size: 21px

    +media-screen-1280x800
      font-size: 18px

    +media-screen-1024x768
      font-size: 18px

    +media-screen-1024x600
      font-size: 18px

  & .flatpickr-calendar.showTimeInput.hasTime .flatpickr-am-pm,
  & .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time-separator
    height: 40px

    +media-screen-1280x800
      height: 35px

    +media-screen-1024x768
      height: 35px

    +media-screen-1024x600
      height: 30px

  & .flatpickr-time .numInputWrapper
    height: 40px

    +media-screen-1280x800
      height: 35px

    +media-screen-1024x768
      height: 35px

    +media-screen-1024x600
      height: 30px

  & .flatpickr-time input.flatpickr-hour,
  & .flatpickr-time input.flatpickr-minute,
  & .flatpickr-time input.flatpickr-second
    font-weight: 400

  & .flatpickr-time input:hover,
  & .flatpickr-time .flatpickr-am-pm:hover,
  & .flatpickr-time input:focus,
  & .flatpickr-time .flatpickr-am-pm:focus,
  & .numInputWrapper:hover,
  & .flatpickr-current-month .flatpickr-monthDropdown-months:hover,
  & .flatpickr-current-year .flatpickr-yearDropdown-years:hover
    background: $c-datepicker-input-hover-bg

  & .numInputWrapper span:hover
    background: $c-datepicker-input-control-hover-bg

  & .flatpickr-month
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    height: 40px
    overflow: visible

    +media-screen-1280x800
      height: 35px

    +media-screen-1024x768
      height: 35px

    +media-screen-1024x600
      height: 30px

  & .flatpickr-current-month,
  & .flatpickr-current-year
    position: relative
    width: auto
    height: 40px
    left: auto
    margin: 0 auto
    padding: 0
    font-size: 21px
    white-space: nowrap

    +media-screen-1280x800
      height: 35px
      font-size: 18px

    +media-screen-1024x768
      height: 35px
      font-size: 16px

    +media-screen-1024x600
      height: 30px
      font-size: 16px

  & .flatpickr-current-month,
  & .flatpickr-current-year
    &:after
      content: '\e921'
      position: absolute
      top: 8px
      z-index: 10
      display: block
      font-family: icons
      font-size: 25px
      color: $c-datepicker-input-icon
      line-height: 1
      font-weight: 400

      +media-screen-1280x800
        top: 7px
        font-size: 20px

      +media-screen-1024x768
        top: 7px
        font-size: 20px

      +media-screen-1024x600
        top: 4px
        font-size: 20px

  & .flatpickr-current-month
    &:after
      left: 155px

      +media-screen-1024x768
        left: 125px

      +media-screen-1024x600
        left: 125px

  & .flatpickr-current-year
    &:after
      right: 10px

      +media-screen-1024x768
        right: 8px

      +media-screen-1024x600
        right: 8px

  & .flatpickr-current-month .flatpickr-monthDropdown-months,
  & .flatpickr-current-year .flatpickr-yearDropdown-years
    height: 40px
    padding: 0 10px
    font-family: $f-sans
    font-weight: 400
    border: 2px solid $c-datepicker-input-border
    color: inherit
    cursor: pointer
    font-size: inherit
    font-family: inherit
    font-weight: 400
    background-color: transparent
    border-radius: 0
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none

    +media-screen-1280x800
      height: 35px

    +media-screen-1024x768
      height: 35px
      padding: 0 8px

    +media-screen-1024x600
      height: 30px
      padding: 0 8px

    &::-ms-expand
      display: none

  & .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
  & .flatpickr-current-month .flatpickr-monthDropdown-months:active,
  & .flatpickr-current-year .flatpickr-yearDropdown-years:focus,
  & .flatpickr-current-year .flatpickr-yearDropdown-years:active
    outline: none

  & .flatpickr-current-month .flatpickr-monthDropdown-months
    width: 180px
    margin: 0
    border-top-left-radius: 12px
    border-bottom-left-radius: 12px
    border-right: none

    +media-screen-1280x800
      border-top-left-radius: 9px
      border-bottom-left-radius: 9px

    +media-screen-1024x768
      width: 144px
      border-top-left-radius: 9px
      border-bottom-left-radius: 9px

    +media-screen-1024x600
      width: 144px
      border-top-left-radius: 7px
      border-bottom-left-radius: 7px

  & .flatpickr-current-year .flatpickr-yearDropdown-years
    width: 100px
    border-top-right-radius: 12px
    border-bottom-right-radius: 12px

    +media-screen-1280x800
      border-top-right-radius: 9px
      border-bottom-right-radius: 9px

    +media-screen-1024x768
      width: 80px
      border-top-right-radius: 9px
      border-bottom-right-radius: 9px

    +media-screen-1024x600
      width: 80px
      border-top-right-radius: 7px
      border-bottom-right-radius: 7px

  & .flatpickr-current-month .numInputWrapper
    width: 80px

    +media-screen-1024x768
      width: 64px

    +media-screen-1024x600
      width: 64px

  & .flatpickr-current-month input.cur-year
    height: 40px
    padding: 0 10px
    font-family: $f-sans
    font-weight: 400
    border: 2px solid $c-datepicker-input-border
    border-top-right-radius: 12px
    border-bottom-right-radius: 12px

    +media-screen-1280x800
      height: 35px
      border-top-right-radius: 9px
      border-bottom-right-radius: 9px

    +media-screen-1024x768
      height: 35px
      padding: 0 8px
      border-top-right-radius: 9px
      border-bottom-right-radius: 9px

    +media-screen-1024x600
      height: 30px
      padding: 0 8px
      border-top-right-radius: 7px
      border-bottom-right-radius: 7px

  & .flatpickr-days
    width: 100%

  & .dayContainer
    width: 100%
    max-width: none

  & .flatpickr-weekday
    font-size: 18px
    font-weight: 700
    color: $c-datepicker-weekday-text
    user-select: none

    +media-screen-1280x800
      font-size: 16px

    +media-screen-1024x768
      font-size: 15px

    +media-screen-1024x600
      font-size: 14px

  & .flatpickr-day
    flex-basis: 14.2%
    width: 14.2%
    height: 65px
    max-width: none
    font-size: 21px
    line-height: 65px
    color: $c-datepicker-day-text
    border-radius: 0
    user-select: none

    +media-screen-1280x800
      height: 52px
      font-size: 18px
      line-height: 48px

    +media-screen-1024x768
      height: 48px
      font-size: 18px
      line-height: 48px

    +media-screen-1024x600
      height: 39px
      font-size: 16px
      line-height: 39px

  & .flatpickr-day.inRange,
  & .flatpickr-day.prevMonthDay.inRange,
  & .flatpickr-day.nextMonthDay.inRange,
  & .flatpickr-day.today.inRange,
  & .flatpickr-day.prevMonthDay.today.inRange,
  & .flatpickr-day.nextMonthDay.today.inRange
    border-color: $c-datepicker-day-in-range-border
    background-color: $c-datepicker-day-in-range-bg

  & .flatpickr-day.today
    background-color: $c-datepicker-day-today-bg
    border-color: $c-datepicker-day-today-border

  & .flatpickr-day:hover,
  & .flatpickr-day.prevMonthDay:hover,
  & .flatpickr-day.nextMonthDay:hover,
  & .flatpickr-day:focus,
  & .flatpickr-day.prevMonthDay:focus,
  & .flatpickr-day.nextMonthDay:focus,
  & .flatpickr-day.today:hover,
  & .flatpickr-day.today:focus
    background-color: $c-datepicker-day-hover-bg
    border-color: $c-datepicker-day-hover-border
    color: $c-datepicker-day-hover-text

  & .flatpickr-day.selected,
  & .flatpickr-day.startRange,
  & .flatpickr-day.endRange,
  & .flatpickr-day.selected.inRange,
  & .flatpickr-day.startRange.inRange,
  & .flatpickr-day.endRange.inRange,
  & .flatpickr-day.selected:focus,
  & .flatpickr-day.startRange:focus,
  & .flatpickr-day.endRange:focus,
  & .flatpickr-day.selected:hover,
  & .flatpickr-day.startRange:hover,
  & .flatpickr-day.endRange:hover,
  & .flatpickr-day.selected.prevMonthDay,
  & .flatpickr-day.startRange.prevMonthDay,
  & .flatpickr-day.endRange.prevMonthDay,
  & .flatpickr-day.selected.nextMonthDay,
  & .flatpickr-day.startRange.nextMonthDay,
  & .flatpickr-day.endRange.nextMonthDay,
  & .flatpickr-day.selected.inRange:hover,
  & .flatpickr-day.selected.prevMonthDay.inRange:hover,
  & .flatpickr-day.selected.nextMonthDay.inRange:hover
    background-color: $c-datepicker-day-active-bg
    border-color: $c-datepicker-day-active-border
    color: $c-datepicker-day-active-text

  & .flatpickr-day.week,
  & .flatpickr-day.week:focus,
  & .flatpickr-day.week:hover,
  & .flatpickr-day.week.prevMonthDay,
  & .flatpickr-day.week.nextMonthDay,
  & .flatpickr-day.week.inRange:hover,
  & .flatpickr-day.week.prevMonthDay.inRange:hover,
  & .flatpickr-day.week.nextMonthDay.inRange:hover

    // IE 11 selected week days highlight
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      background-color: $c-datepicker-day-active-bg
      border-color: $c-datepicker-day-active-border
      color: $c-datepicker-day-active-text

  & .flatpickr-day.inRange:hover
    background-color: $c-datepicker-day-in-range-bg
    border-color: $c-datepicker-day-in-range-border
    color: $c-datepicker-day-in-range-text

  & .flatpickr-day.prevMonthDay.inRange:hover,
  & .flatpickr-day.nextMonthDay.inRange:hover
    color: $c-datepicker-day-inactive-text
    cursor: pointer

  & .flatpickr-day.selected.startRange,
  & .flatpickr-day.startRange.startRange,
  & .flatpickr-day.endRange.startRange,
  & .flatpickr-day.selected.endRange,
  & .flatpickr-day.startRange.endRange,
  & .flatpickr-day.endRange.endRange,
  & .flatpickr-day.selected.startRange.endRange,
  & .flatpickr-day.startRange.startRange.endRange,
  & .flatpickr-day.endRange.startRange.endRange
    border-radius: 0

  & .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  & .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  & .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1))
    box-shadow: -10px 0 0 $c-datepicker-day-active-bg

  & .flatpickr-day.inRange
    box-shadow: -5px 0 0 $c-datepicker-day-in-range-bg, 5px 0 0 $c-datepicker-day-in-range-bg

  & .flatpickr-day.selected.startRange.inRange
    box-shadow: 5px 0 0 $c-datepicker-day-in-range-bg

  & .flatpickr-day.selected.endRange.inRange
    box-shadow: -5px 0 0 $c-datepicker-day-in-range-bg

  & .flatpickr-day.startRange + .flatpickr-day.inRange
    box-shadow: -1px 0 0 $c-datepicker-day-in-range-bg

  & .flatpickr-day.startRange + .flatpickr-day.inRange + .flatpickr-day.endRange
    box-shadow: -1px 0 0 $c-datepicker-day-in-range-bg

  & .flatpickr-day.flatpickr-disabled,
  & .flatpickr-day.flatpickr-disabled:hover,
  & .flatpickr-day.prevMonthDay,
  & .flatpickr-day.nextMonthDay,
  & .flatpickr-day.notAllowed,
  & .flatpickr-day.notAllowed.prevMonthDay,
  & .flatpickr-day.notAllowed.nextMonthDay
    color: $c-datepicker-day-inactive-text
    background: transparent
    border-color: transparent
    cursor: default

  & .flatpickr-day.flatpickr-disabled,
  & .flatpickr-day.flatpickr-disabled:hover
    cursor: not-allowed
    color: $c-datepicker-day-inactive-text

  & .flatpickr-day.prevMonthDay,
  & .flatpickr-day.nextMonthDay
    cursor: pointer

  & .flatpickr-day.week.selected
    border-radius: 0
    box-shadow: -5px 0 0 $c-datepicker-day-active-bg, 5px 0 0 $c-datepicker-day-active-bg

  & .flatpickr-day.week,
  & .flatpickr-day.week.selected
    // IE 11 selected week days highlight
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      border-radius: 0
      box-shadow: -5px 0 0 $c-datepicker-day-active-bg, 5px 0 0 $c-datepicker-day-active-bg
