@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Container
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-container"

#{$block}
  position: relative
  display: block
  width: 1280px
  height: 100%
  min-height: 1024px
  margin: 0 auto

  +media-screen-1280x800
    width: 100%
    min-height: 0

  +media-screen-1024x768
    width: 100%
    min-height: 0

  +media-screen-1024x600
    width: 100%
    min-height: 0

  // $Container MOD: role main
  &_role_main

  // $Container MOD: role transaction
  &_role_transaction

  // $Container MOD: role split
  &_role_split

  // $Container MOD: role summary
  &_role_summary

  // $Container MOD: role tables
  &_role_tables

  // $Container MOD: role transfer table
  &_role_transfer-table

  // $Container MOD: role sales-review
  &_role_sales-review
