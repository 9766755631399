.pos-warning-connection
  position: absolute
  top: 0
  left: calc((100% - 500px) / 2)
  text-align: center
  width: 500px
  background-color: red
  z-index: 999999

  h3
    color: white
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif