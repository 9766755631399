.category-color
  &-default
    background-color: #1c5243 !important
  &-1
    background-color: #3C7867 !important
  &-2
    background-color: #023e18 !important
  &-3
    background-color: #072E24 !important
  &-4
    background-color: #376C6F !important
  &-5
    background-color: #1A494C !important
  &-6
    background-color: #07292B !important
  &-7
    background-color: #629294 !important
  &-8
    background-color: #1A494C !important
  &-9
    background-color: #001516 !important
  &-10
    background-color: #668D98 !important
  &-11
    background-color: #1C434E !important
  &-12
    background-color: #011217 !important
  &-13
    background-color: #14384C !important
  &-14
    background-color: #3C7867 !important
  &-15
    background-color: #023e18 !important
  &-16
    background-color: #072E24 !important
  &-17
    background-color: #376C6F !important
  &-18
    background-color: #1A494C !important
  &-19
    background-color: #07292B !important
  &-20
    background-color: #629294 !important
  &-21
    background-color: #1A494C !important
  &-22
    background-color: #001516 !important
  &-23
    background-color: #668D98 !important
  &-24
    background-color: #668D98 !important

.hidden-button
  visibility: hidden

.pos-invoice__item-deal
  background-color: #c17a7a !important
  font-style: italic

.waste-mode-banner
  background-color: red !important
  display: block !important
  width: 100% !important
  text-align: center
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
  font-size: 18px
  color: #ffffff
  padding-top: 5px
  padding-bottom: 5px
  font-weight: bold


.item-count
  font-size: 9px
  font-style: italic

.disabled-menu
  background: red !important

.pos-warning-loyalty
  position: absolute
  bottom: 0
  left: 0
  text-align: center
  width: 680px
  background-color: red
  z-index: 999999

  h3
    color: white
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif  

.pos-item__quantity
  margin-top: 65px

.menu-item-disabled
  opacity: 0.4

@media screen and (height: 800px)
  .pos-invoice__panel-full
    height: 630px !important

@media screen and (height: 768px)
  .pos-invoice__panel-full
    height: 600px !important

@media screen and (height: 600px)
  .pos-invoice__panel-full
    height: 460px !important
