@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Servers Overview
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-servers-overview"

#{$block}
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  width: 100%
  height: 100%
  padding: 30px 10px 20px

  +media-screen-1280x800
    padding: 24px 10px 16px

  +media-screen-1024x768
    padding: 22px 8px 15px

  +media-screen-1024x600
    padding: 18px 8px 12px


  // $Servers Overview header
  &__header
    display: block
    width: 100%


  // $Servers Overview title
  &__title
    display: block
    font-family: $f-sans
    font-size: 56px
    line-height: 68px
    font-weight: 800
    color: $c-servers-overview-title-text
    text-align: center
    text-transform: uppercase

    +media-screen-1280x800
      font-size: 45px
      line-height: 54px

    +media-screen-1024x768
      font-size: 42px
      line-height: 51px

    +media-screen-1024x600
      font-size: 33px
      line-height: 41px


  // $Servers Overview subtitle
  &__subtitle
    display: block
    padding-top: 25px
    font-family: $f-sans
    font-size: 21px
    line-height: 21px
    font-weight: 800
    color: $c-servers-overview-subtitle-text
    text-align: center

    +media-screen-1280x800
      padding-top: 20px
      font-size: 18px
      line-height: 18px

    +media-screen-1024x768
      padding-top: 18px
      font-size: 16px
      line-height: 16px

    +media-screen-1024x600
      padding-top: 14px
      font-size: 14px
      line-height: 14px


  // $Servers Overview icon
  &__icon
    flex-grow: 0
    flex-shrink: 0
    display: block
    margin-top: 154px
    margin-bottom: 127px

    +media-screen-1280x800
      margin-top: 123px
      margin-bottom: 101px

    +media-screen-1024x768
      margin-top: 115px
      margin-bottom: 95px

    +media-screen-1024x600
      margin-top: 92px
      margin-bottom: 76px

    &:before
      content: ''
      display: block
      font-family: icons
      font-size: 173px
      line-height: 173px
      font-weight: 400
      color: $c-servers-overview-icon

      +media-screen-1280x800
        font-size: 138px
        line-height: 138px

      +media-screen-1024x768
        font-size: 129px
        line-height: 129px

      +media-screen-1024x600
        font-size: 103px
        line-height: 103px

    // $Servers Overview icon MOD: role scan
    &_role_scan
      &:before
        content: '\e920'
        color: $c-servers-overview-icon-scan


  // $Servers Overview error
  &__error
    display: block
    font-family: $f-sans
    font-size: 35px
    line-height: 35px
    font-weight: 800
    color: $c-servers-overview-error-text
    text-align: center
    text-transform: uppercase

    +media-screen-1280x800
      font-size: 28px
      line-height: 28px

    +media-screen-1024x768
      font-size: 26px
      line-height: 26px

    +media-screen-1024x600
      font-size: 21px
      line-height: 21px


  // $Servers Overview info
  &__info
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: 100%


  // $Servers Overview servers
  &__servers
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 755px
    margin: 0 auto
    padding-top: 26px
    border-radius: 12px
    background-color: $c-servers-overview-servers-bg

    +media-screen-1280x800
      padding-top: 21px
      border-radius: 9px

    +media-screen-1024x768
      width: 602px
      padding-top: 20px
      border-radius: 9px

    +media-screen-1024x600
      width: 602px
      padding-top: 15px
      border-radius: 7px


  // $Servers Overview servers control
  &__servers-control
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 48px
    height: 77px
    margin: 0
    padding: 0
    font-size: 0
    line-height: 0
    color: $c-servers-overview-servers-bg
    text-align: center
    border: none
    box-shadow: none
    border-radius: 12px
    background-color: $c-servers-overview-servers-control-bg
    cursor: pointer
    transition: all .2s

    +media-screen-1280x800
      height: 61px
      border-radius: 9px

    +media-screen-1024x768
      width: 38px
      height: 57px
      border-radius: 9px

    +media-screen-1024x600
      width: 38px
      height: 46px
      border-radius: 7px

    &:before
      content: ''
      display: inline-block
      font-family: icons
      font-size: 24px
      line-height: 24px
      color: $c-servers-overview-servers-control-icon

      +media-screen-1280x800
        font-size: 19px
        line-height: 19px

      +media-screen-1024x768
        font-size: 18px
        line-height: 18px

      +media-screen-1024x600
        font-size: 15px
        line-height: 15px

    &:hover
      background-color: $c-servers-overview-servers-control-hover-bg

    &:focus
      outline: none

    // $Servers Overview servers control STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Servers Overview servers control MOD: role previous
    &_role_previous
      &:before
        content: '\e901'

    // $Servers Overview servers control MOD: role next
    &_role_next
      &:before
        content: '\e902'


  // $Servers Overview servers list
  &__servers-list
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: calc(100% - 96px)
    padding: 0 18px
    text-align: center

    +media-screen-1024x768
      width: calc(100% - 76px)
      padding: 0 14px

    +media-screen-1024x600
      width: calc(100% - 76px)
      padding: 0 14px


  // $Servers Overview servers scroll
  &__servers-scroll
    position: relative
    display: block
    width: 100%
    overflow: hidden
    text-align: center


  // $Servers Overview servers row
  &__servers-row
    display: inline-flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start

    &:not(:last-child)
      margin-bottom: 20px

      +media-screen-1280x800
        margin-bottom: 16px

      +media-screen-1024x768
        margin-bottom: 15px

      +media-screen-1024x600
        margin-bottom: 12px


  // $Servers Overview servers group
  &__servers-group
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 623px

    +media-screen-1024x768
      width: 498px

    +media-screen-1024x600
      width: 498px

    &:not(:last-child)
      margin-right: 7px

      +media-screen-1024x768
        margin-right: 6px

      +media-screen-1024x600
        margin-right: 6px


  // $Servers Overview server
  &__server
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    width: 203px
    height: 77px
    padding: 8px
    border-radius: 12px
    background-color: $c-servers-overview-server-bg
    cursor: pointer
    user-select: none
    text-decoration: none
    overflow: hidden
    transition: all .2s

    +media-screen-1280x800
      height: 61px
      padding: 6px 8px
      border-radius: 9px

    +media-screen-1024x768
      width: 162px
      height: 57px
      padding: 5px
      border-radius: 9px

    +media-screen-1024x600
      width: 162px
      height: 46px
      padding: 3px
      border-radius: 7px

    &:hover
      background-color: $c-servers-overview-server-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 7px

      +media-screen-1024x768
        margin-right: 6px

      +media-screen-1024x600
        margin-right: 6px

    // $Servers Overview server STATE: active
    &.is-active
      background-color: $c-servers-overview-server-active-bg

    // $Servers Overview server STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Servers Overview server text
  &__server-text
    display: block
    margin-bottom: 2px
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-servers-overview-server-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 15px
      line-height: 19px

    +media-screen-1024x768
      font-size: 15px
      line-height: 18px

    +media-screen-1024x600
      font-size: 13px
      line-height: 15px


  // $Servers Overview server meta
  &__server-meta
    display: block
    font-family: $f-sans
    font-size: 12px
    line-height: 15px
    font-weight: 700
    color: $c-servers-overview-server-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 11px
      line-height: 12px

    +media-screen-1024x768
      font-size: 10px
      line-height: 11px

    +media-screen-1024x600
      font-size: 9px
      line-height: 10px


  // $Servers Overview server meta line
  &__server-meta-line
    display: block

    &:not(:last-child)
      margin-bottom: 4px

      +media-screen-1280x800
        margin-bottom: 3px

      +media-screen-1024x768
        margin-bottom: 3px

      +media-screen-1024x600
        margin-bottom: 2px


  // $Servers Overview actions
  &__actions
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%
    padding-top: 43px

    +media-screen-1280x800
      padding-top: 34px

    +media-screen-1024x768
      padding-top: 32px

    +media-screen-1024x600
      padding-top: 26px


  // $Servers Overview action
  &__action
    display: block
    width: 191px
    height: 77px
    margin: 0
    padding: 0 5px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 30px
    line-height: 77px
    font-weight: 700
    color: $c-servers-overview-action-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-servers-overview-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 61px
      font-size: 24px
      line-height: 61px
      border-radius: 9px

    +media-screen-1024x768
      width: 152px
      height: 57px
      font-size: 22px
      line-height: 57px
      border-radius: 9px

    +media-screen-1024x600
      width: 152px
      height: 46px
      font-size: 20px
      line-height: 46px
      border-radius: 7px

    &:hover
      background-color: $c-servers-overview-action-hover-bg

    &:focus
      outline: none

    // $Servers Overview action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none
