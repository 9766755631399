.pos-environment-banner
  position: absolute
  bottom: 0
  left: 0
  text-align: center
  width: 150px
  background-color: red
  z-index: 999999

  h3
    color: white
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif