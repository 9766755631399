.pos-void-qr
  width: 200px
  height: 200px
  position: absolute
  top: 0
  right: 0

  video
    width: 200px
    height: 200px

.pos-void__footer
  margin-top: 20px

  .pos-void__message
    flex-shrink: 1
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
    font-size: 20px
    line-height: 25px
    font-weight: 500
    text-align: center

.void-all-button
  width: 100% !important
  padding-left: 10px !important
  padding-right: 10px !important
  background-color: #a36969

.void-subtitle
  text-align: center
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
  color: red

.pos-void__group
  padding-top: 20px !important

.is-eft-row
  background-color: #c7a5a5

.line-item-vodied
  background-color: #c7a5a5 !important