@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Header
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-header"

#{$block}
  position: relative
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  width: 100%
  padding: 7px 12px

  +media-screen-1280x800
    padding: 7px 12px

  +media-screen-1024x768
    padding: 6px 10px

  +media-screen-1024x600
    padding: 6px 10px

  // $Header MOD: role main
  &_role_main
    z-index: 200

  // $Header MOD: role welcome
  &_role_welcome
    z-index: 400
    background-color: $c-header-welcome-bg

    &:before,
    &:after
      content: ''
      position: absolute
      top: 0
      bottom: 0
      z-index: 400
      display: block
      width: calc((100vw - 100%) / 2)
      background-color: $c-header-welcome-bg

    &:before
      right: 100%

    &:after
      left: 100%

  // $Header MOD: role tables
  &_role_tables
    z-index: 200

  // $Header MOD: role sales-review
  &_role_sales-review
    padding: 5px
    background-color: $c-header-sales-review-bg

    &:before,
    &:after
      content: ''
      position: absolute
      top: 0
      bottom: 0
      display: block
      width: calc((100vw - 100%) / 2)
      background-color: $c-header-sales-review-bg

    &:before
      right: 100%

    &:after
      left: 100%


  // $Header area
  &__area
    display: block

    // $Header area MOD: role info
    &_role_info
      flex-grow: 1
      flex-shrink: 1

      #{$block}_role_main &,
      #{$block}_role_tables &
        width: calc(100% - 402px)
        padding-right: 12px

      +media-screen-1024x768
        #{$block}_role_main &,
        #{$block}_role_tables &
          width: calc(100% - 320px)

      +media-screen-1024x600
        #{$block}_role_main &,
        #{$block}_role_tables &
          width: calc(100% - 320px)

    // $Header area MOD: role actions
    &_role_actions
      flex-grow: 0
      flex-shrink: 0

      #{$block}_role_main &,
      #{$block}_role_tables &
        width: 402px

      +media-screen-1024x768
        #{$block}_role_main &,
        #{$block}_role_tables &
          width: 320px

      +media-screen-1024x600
        #{$block}_role_main &,
        #{$block}_role_tables &
          width: 320px


  // $Header info
  &__info
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    height: 48px
    padding: 10px 22px

    #{$block}_role_main &,
    #{$block}_role_tables &
      background-color: $c-header-info-bg
      border-radius: 12px

    +media-screen-1280x800
      height: 35px

      #{$block}_role_main &,
      #{$block}_role_tables &
        border-radius: 9px

    +media-screen-1024x768
      height: 35px
      padding: 5px 15px

      #{$block}_role_main &,
      #{$block}_role_tables &
        border-radius: 9px

    +media-screen-1024x600
      height: 30px
      padding: 5px 15px

      #{$block}_role_main &,
      #{$block}_role_tables &
        border-radius: 7px


  // $Header menu toggle
  &__menu-toggle
    flex-grow: 0
    flex-shrink: 0
    display: block
    margin: 0
    padding: 0
    font-size: 0
    line-height: 0
    color: transparent
    border: none
    background-color: transparent
    box-shadow: none
    cursor: pointer
    transition: all .2s

    &:before
      content: '\e91b'
      display: block
      width: 30px
      font-family: icons
      font-size: 30px
      line-height: 10px
      color: $c-header-menu-toggle-icon
      text-align: center
      transition: all .2s

      +media-screen-1280x800
        width: 24px
        font-size: 24px
        line-height: 10px

      +media-screen-1024x768
        width: 22px
        font-size: 22px
        line-height: 10px

      +media-screen-1024x600
        width: 20px
        font-size: 20px
        line-height: 10px

    &:hover
      &:before
        color: $c-header-menu-toggle-hover-icon

    &:focus
      outline: none

    // $Header menu toggle STATE: active
    &.is-active
      &:before
        content: '\e900'
        font-size: 26px

        +media-screen-1280x800
          font-size: 22px

        +media-screen-1024x768
          font-size: 20px

        +media-screen-1024x600
          font-size: 18px


  // $Header sidebar toggle
  &__sidebar-toggle
    flex-grow: 0
    flex-shrink: 0
    display: block
    margin: 0
    padding: 0
    font-size: 0
    line-height: 0
    color: transparent
    border: none
    background-color: transparent
    box-shadow: none
    cursor: pointer
    transition: all .2s

    &:before
      content: '\e904'
      display: block
      width: 40px
      font-family: icons
      font-size: 40px
      line-height: 30px
      color: $c-header-sidebar-toggle-icon
      text-align: center
      transition: all .2s

      +media-screen-1280x800
        width: 28px
        font-size: 28px
        line-height: 22px

      +media-screen-1024x768
        width: 28px
        font-size: 28px
        line-height: 22px

      +media-screen-1024x600
        width: 24px
        font-size: 24px
        line-height: 20px

    &:hover
      &:before
        color: $c-header-sidebar-toggle-hover-icon

    &:focus
      outline: none

    // $Header sidebar toggle STATE: active
    &.is-active
      &:before
        content: '\e900'
        font-size: 30px

        +media-screen-1280x800
          font-size: 22px

        +media-screen-1024x768
          font-size: 22px

        +media-screen-1024x600
          font-size: 20px


  // $Header labels
  &__labels
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 0 55px

    +media-screen-1024x768
      padding: 0 40px

    +media-screen-1024x600
      padding: 0 40px


  // $Header label
  &__label
    flex-grow: 0
    flex-shrink: 0
    display: block
    font-family: $f-sans
    font-size: 21px
    line-height: 1
    font-weight: 500
    color: $c-header-label-text
    text-align: center
    white-space: nowrap

    +media-screen-1280x800
      font-size: 17px

    +media-screen-1024x768
      font-size: 16px

    +media-screen-1024x600
      font-size: 16px

    +media-screen-960x600
      font-size: 14px


  // $Header search toggle
  &__search-toggle
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 140px
    height: 30px
    margin: 0
    padding: 0 15px
    font-family: $f-sans
    font-size: 14px
    line-height: 30px
    letter-spacing: 0.5px
    text-transform: uppercase
    color: $c-header-search-toggle-text
    text-align: left
    border: none
    border-radius: 15px
    background-color: $c-header-search-toggle-bg
    box-shadow: none
    cursor: pointer
    transition: all .2s

    +media-screen-1280x800
      height: 22px
      font-size: 12px
      line-height: 22px

    +media-screen-1024x768
      width: 110px
      height: 22px
      padding: 0 8px
      font-size: 12px
      line-height: 22px

    +media-screen-1024x600
      width: 110px
      height: 20px
      padding: 0 8px
      font-size: 12px
      line-height: 20px

    &:after
      content: '\e905'
      position: absolute
      top: 0
      right: 15px
      display: block
      font-family: icons
      font-size: 20px
      line-height: 30px
      color: $c-header-search-toggle-icon
      transition: all .2s

      +media-screen-1280x800
        font-size: 14px
        line-height: 22px

      +media-screen-1024x768
        right: 8px
        font-size: 14px
        line-height: 22px

      +media-screen-1024x600
        right: 8px
        font-size: 14px
        line-height: 20px

    &:hover
      color: $c-header-search-toggle-hover-text
      background-color: $c-header-search-toggle-hover-bg

      &:after
        color: $c-header-search-toggle-hover-icon

    &:focus
      outline: none

    // $Header search toggle STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Header actions
  &__actions
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%


  // $Header action
  &__action
    flex-grow: 1
    flex-shrink: 1
    display: block
    height: 48px
    margin: 0
    padding: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 21px
    line-height: 48px
    font-weight: 500
    color: $c-header-action-text
    text-align: center
    border-radius: 12px
    background-color: $c-header-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 35px
      font-size: 17px
      line-height: 35px
      border-radius: 9px

    +media-screen-1024x768
      height: 35px
      font-size: 16px
      line-height: 35px
      border-radius: 9px

    +media-screen-1024x600
      height: 30px
      font-size: 16px
      line-height: 30px
      border-radius: 7px

    &:hover
      background-color: $c-header-action-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 5px

    // $Header action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Header title
  &__title
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 60px
    line-height: 60px
    font-weight: 500
    color: $c-header-title-text
    text-align: center
    text-transform: uppercase

    +media-screen-1280x800
      font-size: 48px
      line-height: 48px

    +media-screen-1024x768
      font-size: 45px
      line-height: 45px

    +media-screen-1024x600
      font-size: 35px
      line-height: 35px
