@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Summary
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-summary"

#{$block}
  display: block
  width: 100%
  height: 100%
  padding: 50px 175px

  +media-screen-1280x800
    padding: 37px 175px

  +media-screen-1024x768
    padding: 37px 140px

  +media-screen-1024x600
    padding: 30px 140px


  // $Summary data
  &__data
    display: block
    width: 100%
    margin-bottom: 45px

    +media-screen-1280x800
      margin-bottom: 35px

    +media-screen-1024x768
      margin-bottom: 33px

    +media-screen-1024x600
      margin-bottom: 25px


  // $Summary actions
  &__actions
    display: block
    width: 100%


  // $Summary action
  &__action
    display: block
    width: 100%
    height: 196px
    margin: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 70px
    line-height: 196px
    font-weight: 700
    text-align: center
    text-transform: uppercase
    color: $c-summary-action-text
    border-radius: 21px
    background-color: $c-summary-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 155px
      font-size: 55px
      line-height: 155px
      border-radius: 18px

    +media-screen-1024x768
      height: 145px
      font-size: 52px
      line-height: 145px
      border-radius: 15px

    +media-screen-1024x600
      height: 115px
      font-size: 42px
      line-height: 115px
      border-radius: 13px

    &:hover
      background-color: $c-summary-action-hover-bg

    &:focus
      outline: none

    // $Summary action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none
