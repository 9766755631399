.pos-servers-overview__action
  width: 40% !important

.pos-servers-overview__action:not(:first-child)
  margin-left: 10px

.server-overview-action-print
  background-color: #827ac1 !important

.server-overview-action-waste
  background-color: #827ac1 !important

.close-message
  display: block
  margin-top: 20px
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
  font-size: 24px
  line-height: 24px
  font-weight: 800
  color: #000000
  text-align: center
  text-transform: uppercase