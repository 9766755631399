.x-pos-error-body
    background-color: red
    font-family: 'Montserrat', sans-serif
    width: 100%
    height: 100%

.x-pos-error-container
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    padding: 10px
    width: 600px
    background-color: #ffffff
    color: red

.x-pos-error-container h2
    margin: 20px 0 20px 0

.x-pos-error-container p
    margin-top: 10px

.x-pos-error-box
    text-align: center

.x-pos-error-progressBar-outer
    width: 500px
    margin: 0 50px 0 50px
    background-color: #ffffff
    border: 1px solid #cccccc

.reload-button
    width: 100%
    height: 50px
    font-size: 20px
    font-weight: bold
    background-color: #6699ff
    color: #ffffff
    border: none
    margin-top: 20px

body
    cursor: none