=font-face ($font-family, $font-weight, $file-name, $font-style: normal)
  font-family: $font-family
  font-style: $font-style
  font-weight: $font-weight
  src: url("../../fonts/#{$file-name}.otf")


@font-face
  +font-face($font-family: Montserrat, $font-weight: 300, $file-name: Montserrat-Light)


@font-face
  +font-face($font-family: Montserrat, $font-weight: 400, $file-name: Montserrat-Regular)


@font-face
  +font-face($font-family: Montserrat, $font-weight: 500, $file-name: Montserrat-Medium)


@font-face
  +font-face($font-family: Montserrat, $font-weight: 700, $file-name: Montserrat-Bold)


@font-face
  +font-face($font-family: Montserrat, $font-weight: 800, $file-name: Montserrat-ExtraBold)


// Page icons, custom set from icomoon
@font-face
  font-family: 'icons'
  src: url("../../fonts/icons/icons.woff") format("woff"), url("../../fonts/icons/icons.ttf") format("truetype"), url("../../fonts/icons/icons.svg") format("svg")
  font-weight: normal
  font-style: normal
