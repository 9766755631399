@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Body
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-body"

#{$block}
  position: relative
  display: block
  width: calc(100vw - (100vw - 100%))
  min-width: 1280px
  height: 100vh
  min-height: 1024px
  margin: 0
  padding: 0
  overflow: hidden

  +media-screen-1280x800
    min-width: 0
    min-height: 0

  +media-screen-1024x768
    min-width: 0
    min-height: 0

  +media-screen-1024x600
    min-width: 0
    min-height: 0
