////////////////////////////////////////
// Reset styles
////////////////////////////////////////

*, *:before, *:after
  box-sizing: border-box
  margin: 0
  padding: 0

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section
  display: block

table
  border-collapse: collapse
  border-spacing: 0
