.loyalty-details
  width: 100%
  height: 100%

  .loyalty-name
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
    text-align: center
    padding: 5px 0 5px 0

  .loyalty-subtitle
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
    font-size: 12px
    text-align: center
    padding: 5px 0 5px 0

  .loyalty-invalid-profile
    color: #ff0000
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
    text-align: center
    padding: 5px 0 5px 0

  .loyalty-deals
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
    border-top: 1px solid #ababab
    table
      width: 100%
      margin-top: 5px
      border-collapse: collapse
      border: 1px solid #ababab

      tr
        border: 1px solid #ababab

      th
        padding: 3px 0 3px 0
        text-align: center

      td
        padding: 3px 0 3px 0
        text-align: center
       
      .col-50
        width: 50px
      
      .col-100
        width: 100px