@media screen and (width: 1280px) and (height: 800px) and (orientation: landscape)
  .pos-invoice__panel-split-left
    height: 625px !important

  .pos-invoice__panel-split-right
    height: 555px !important

@media screen and (width: 1024px) and (height: 768px) and (orientation: landscape)
  .pos-invoice__panel-split-left
    height: 600px !important

  .pos-invoice__panel-split-right
    height: 530px !important

@media screen and (width: 1024px) and (height: 600px) and (orientation: landscape), screen and (min-width: 960px) and (max-width: 965px) and (min-height: 600px) and (max-height: 605px) and (orientation: landscape)
  .pos-invoice__panel-split-left
    height: 460px !important

  .pos-invoice__panel-split-right
    height: 405px !important

@media screen and (min-width: 960px) and (max-width: 965px) and (min-height: 600px) and (max-height: 605px) and (orientation: landscape)
  .pos-invoice__panel-split-left
    height: 460px !important

  .pos-invoice__panel-split-right
    height: 405px !important