@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Main
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-main"

#{$block}
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: stretch
  width: 100%
  height: calc(100% - 62px)
  padding: 0 12px

  +media-screen-1280x800
    height: calc(100% - 49px)

  +media-screen-1024x768
    height: calc(100% - 47px)
    padding: 0 10px

  +media-screen-1024x600
    height: calc(100% - 42px)
    padding: 0 10px


  // $Main index
  &__index
    flex-grow: 1
    flex-shrink: 1
    position: relative
    display: block
    width: calc(100% - 402px)
    height: 100%
    padding: 7px 14px 0 0

    +media-screen-1024x768
      width: calc(100% - 320px)
      padding: 3px 12px 0 0

    +media-screen-1024x600
      width: calc(100% - 320px)
      padding: 3px 12px 0 0


  // $Main order
  &__order
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    height: 100%
    width: 402px

    +media-screen-1024x768
      width: 320px

    +media-screen-1024x600
      width: 320px


  // $Main menu
  &__menu
    display: block
    width: 100%


  // $Main version
  &__version
    position: absolute
    bottom: 7px
    left: 0
    right: 20px
    display: block
    font-family: $f-sans
    font-size: 12px
    line-height: 1
    font-weight: 500
    color: $c-main-version-text
    text-align: center

    +media-screen-1024x768
      bottom: 6px
      font-size: 11px

    +media-screen-1024x600
      bottom: 6px
      font-size: 11px


  // $Main invoice
  &__invoice
    display: block
    width: 100%
    margin-bottom: 7px

    +media-screen-1024x768
      margin-bottom: 6px

    +media-screen-1024x600
      margin-bottom: 6px


  // $Main controls
  &__controls
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%


  // $Main numpad
  &__numpad
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: 234px

    +media-screen-1280x800
      width: 170px

    +media-screen-1024x768
      width: 170px

    +media-screen-1024x600
      width: 140px


  // $Main actions
  &__actions
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    width: calc(100% - 234px)
    padding-left: 20px

    +media-screen-1280x800
      width: calc(100% - 170px)

    +media-screen-1024x768
      width: calc(100% - 170px)
      padding-left: 16px

    +media-screen-1024x600
      width: calc(100% - 140px)
      padding-left: 16px


  // $Main action
  &__action
    display: block
    width: 100%
    height: 63px
    margin: 0
    padding: 0 5px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-main-action-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-main-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 46px
      font-size: 16px
      line-height: 18px
      border-radius: 9px

    +media-screen-1024x768
      height: 46px
      font-size: 16px
      line-height: 18px
      border-radius: 9px

    +media-screen-1024x600
      height: 38px
      font-size: 16px
      line-height: 18px
      border-radius: 7px

    &:hover
      background-color: $c-main-action-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-bottom: 14px

      +media-screen-1280x800
        margin-bottom: 10px

      +media-screen-1024x768
        margin-bottom: 10px

      +media-screen-1024x600
        margin-bottom: 8px

    // $Main action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Main subtotal
  &__subtotal
    position: absolute
    bottom: 0
    left: 50%
    display: block
    width: 360px
    height: 98px
    margin: 0
    padding: 0
    margin-left: -180px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 40px
    line-height: 98px
    font-weight: 500
    color: $c-main-subtotal-text
    text-transform: uppercase
    text-align: center
    border-radius: 35px 35px 0 0
    background-color: $c-main-subtotal-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 73px
      font-size: 30px
      line-height: 73px
      border-radius: 27px 27px 0 0

    +media-screen-1024x768
      width: 290px
      height: 73px
      margin-left: -145px
      font-size: 30px
      line-height: 73px
      border-radius: 27px 27px 0 0

    +media-screen-1024x600
      width: 290px
      height: 60px
      margin-left: -145px
      font-size: 24px
      line-height: 60px
      border-radius: 21px 21px 0 0

    &:hover
      background-color: $c-main-subtotal-hover-bg

    &:focus
      outline: none

    // $Main subtotal STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

