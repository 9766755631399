// DEV MIXINS
=testbg($red: 255, $green: 0, $blue: 0, $alpha: 0.3)
  background-color: rgba($red, $green, $blue, $alpha)


=testbd($width: 1px, $line-type: solid, $color: green)
  border: $width $line-type $color


=testout($width: 1px, $line-type: solid, $color: blue)
  outline: $width $line-type $color


=testcount($element: "*", $depth: 1)
  counter-reset: test-counter

  %counter-number
    position: absolute
    top: 5px
    right: 5px
    z-index: 100
    counter-increment: test-counter
    content: counter(test-counter)
    display: block
    font-size: 12px !important
    line-height: 1 !important
    font-weight: normal !important
    color: red !important

  @if $depth == 1
    & > #{$element}
      position: relative

      &:before
        @extend %counter-number

  @else
    & #{$element}
      position: relative

      &:before
        @extend %counter-number


// Media queries
=media-screen-1280x800
  @media screen and (width: 1280px) and (height: 800px) and (orientation: landscape)
    @content

=media-screen-1024x768
  @media screen and (width: 1024px) and (height: 768px) and (orientation: landscape)
    @content

=media-screen-1024x600
  @media screen and (width: 1024px) and (height: 600px) and (orientation: landscape), screen and (min-width: 960px) and (max-width: 965px) and (min-height: 600px) and (max-height: 605px) and (orientation: landscape)
    @content

=media-screen-960x600
  @media screen and (min-width: 960px) and (max-width: 965px) and (min-height: 600px) and (max-height: 605px) and (orientation: landscape)
    @content
