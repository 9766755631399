.pos-card-payment-status
  position: absolute
  bottom: 100px
  right: 0
  text-align: center
  width: 400px
  z-index: 99
  color: white
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif

  div.pos-terminal-container
    padding: 3px 0 3px 0
    margin-top: 3px

    p.pos-terminal-failure
      background-color: #d06101

    p.pos-terminal-success
      background-color: #4d8b5d

  div.pos-terminal-container:last-child
    margin-top: 0px



