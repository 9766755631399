@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Modal window
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-modal"

#{$block}
  position: relative
  display: block
  width: 100%
  max-width: 960px
  margin: auto
  border-radius: 15px
  border: 1px solid $c-modal-border
  background-color: $c-modal-bg

  #{$block}-portal &
    z-index: 400

  +media-screen-1280x800
    border-radius: 12px

  +media-screen-1024x768
    border-radius: 12px

  +media-screen-1024x600
    border-radius: 9px

  // $Modal window MOD: role item
  // $Modal window MOD: role message
  // $Modal window MOD: role void
  // $Modal window MOD: role search
  // $Modal window MOD: role sign-out
  // $Modal window MOD: role sign-in
  // $Modal window MOD: role new table
  // $Modal window MOD: role sale
  // $Modal window MOD: role servers-overview
  // $Modal window MOD: role bookings
  // $Modal window MOD: role pin-entry-devices
  &_role_item,
  &_role_message,
  &_role_void,
  &_role_search,
  &_role_sign-out,
  &_role_sign-in,
  &_role_new-table,
  &_role_sale,
  &_role_servers-overview,
  &_role_bookings,
  &_role_pin-entry-devices
    flex-grow: 0
    flex-shrink: 0
    width: 777px
    height: 777px

    +media-screen-1280x800
      height: 620px

    +media-screen-1024x768
      width: 620px
      height: 582px

    +media-screen-1024x600
      width: 620px
      height: 466px

  &_role_item
    height: 870px

    +media-screen-1280x800
      height: 696px

    +media-screen-1024x768
      height: 654px

    +media-screen-1024x600
      height: 524px


  // $Modal window content
  &__content
    display: block
    width: 100%
    height: 100%


  // $Modal window loader
  // $Modal window popup
  &__loader,
  &__popup
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 500
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 15px
    background-color: $c-modal-overlay-bg

    +media-screen-1280x800
      border-radius: 12px

    +media-screen-1024x768
      border-radius: 12px

    +media-screen-1024x600
      border-radius: 9px

    // $Modal loader MOD: type light
    // $Modal popup MOD: type light
    &_type_light
      background-color: $c-modal-overlay-light-bg


  @at-root
    // $Modal window portal
    &-portal


    // $Modal window overlay
    &-overlay
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: 300
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      background-color: $c-modal-overlay-bg

      // $Modal window overlay MOD: type transparent
      &_type_transparent
        background-color: transparent

      // $Modal window overlay MOD: type light
      &_type_light
        background-color: $c-modal-overlay-light-bg
