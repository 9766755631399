@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Void
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-void"

#{$block}
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  width: 100%
  padding: 10px 25px

  +media-screen-1280x800
    padding: 9px 25px

  +media-screen-1024x768
    padding: 9px 20px

  +media-screen-1024x600
    padding: 8px 20px


  // $Void header
  &__header
    display: block
    width: 100%


  // $Void title
  &__title
    display: block
    font-family: $f-sans
    font-size: 50px
    line-height: 60px
    font-weight: 700
    color: $c-void-title-text
    text-align: center
    text-transform: uppercase

    +media-screen-1280x800
      font-size: 40px
      line-height: 48px

    +media-screen-1024x768
      font-size: 37px
      line-height: 45px

    +media-screen-1024x600
      font-size: 30px
      line-height: 36px


  // $Void group
  &__group
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%
    padding-top: 50px

    +media-screen-1280x800
      padding-top: 40px

    +media-screen-1024x768
      padding-top: 37px

    +media-screen-1024x600
      padding-top: 30px


  // $Void item select
  &__item-select
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 402px

    +media-screen-1024x768
      width: 320px

    +media-screen-1024x600
      width: 320px


  // $Void actions
  &__actions
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    width: 100%
    padding-left: 43px

    +media-screen-1024x768
      padding-left: 34px

    +media-screen-1024x600
      padding-left: 34px


  // $Void action
  &__action
    display: block
    width: 100%
    height: 63px
    margin: 0
    padding: 0 5px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-void-action-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-void-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 50px
      font-size: 18px
      line-height: 21px
      border-radius: 9px

    +media-screen-1024x768
      height: 46px
      font-size: 16px
      line-height: 18px
      border-radius: 9px

    +media-screen-1024x600
      height: 40px
      font-size: 15px
      line-height: 17px
      border-radius: 7px

    &:hover
      background-color: $c-void-action-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-bottom: 14px

      +media-screen-1280x800
        margin-bottom: 13px

      +media-screen-1024x768
        margin-bottom: 12px

      +media-screen-1024x600
        margin-bottom: 10px

    // $Void action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none
