@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Sales Review
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-sales-review"

#{$block}
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  width: 100%
  height: calc(100% - 70px)
  padding: 45px 0 40px

  +media-screen-1280x800
    height: calc(100% - 56px)
    padding: 36px 0 32px

  +media-screen-1024x768
    height: calc(100% - 52px)
    padding: 34px 0 30px

  +media-screen-1024x600
    height: calc(100% - 45px)
    padding: 27px 0 24px


  // $Sales Review table
  &__table
    position: relative
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: 1205px
    margin: 0 auto
    border: 5px solid $c-sales-review-table-border

    +media-screen-1024x768
      width: calc(100% - 40px)

    +media-screen-1024x600
      width: calc(100% - 40px)


  // $Sales Review table content
  &__table-content
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0


  // $Sales Review controls
  &__controls
    display: block
    width: 100%


  // $Sales Review field
  &__field
    display: block
    width: 297px
    margin: 0 auto
    padding-top: 15px

    +media-screen-1280x800
      padding-top: 13px

    +media-screen-1024x768
      width: 238px
      padding-top: 12px

    +media-screen-1024x600
      width: 238px
      padding-top: 9px


  // $Sales Review keyboard
  &__keyboard
    display: block
    width: 100%
    padding-top: 15px

    +media-screen-1280x800
      padding-top: 13px

    +media-screen-1024x768
      padding-top: 12px

    +media-screen-1024x600
      padding-top: 9px


  // $Sales Review control
  &__control
    position: absolute
    bottom: 67px
    right: 70px
    display: block

    +media-screen-1280x800
      bottom: 54px

    +media-screen-1024x768
      bottom: 50px
      right: 56px

    +media-screen-1024x600
      bottom: 40px
      right: 56px


  // $Sales Review actions
  &__actions
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 540px
    margin: 54px 0 48px 90px

    +media-screen-1280x800
      margin: 43px 0 38px 90px

    +media-screen-1024x768
      width: 432px
      margin: 40px 0 36px 72px

    +media-screen-1024x600
      width: 432px
      margin: 32px 0 28px 72px


  // $Sales Review action
  &__action
    display: block
    width: 140px
    height: 70px
    margin: 0
    padding: 0 5px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-sales-review-action-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-sales-review-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 52px
      font-size: 18px
      line-height: 21px
      border-radius: 9px

    +media-screen-1024x768
      width: 110px
      height: 52px
      padding: 0 10px
      font-size: 16px
      line-height: 18px
      border-radius: 9px

    +media-screen-1024x600
      width: 110px
      height: 42px
      padding: 0 10px
      font-size: 15px
      line-height: 17px
      border-radius: 7px

    &:hover
      background-color: $c-sales-review-action-hover-bg

    &:focus
      outline: none

    // $Sales Review action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Sales Review action MOD: role display sale
    &_role_display-sale
      color: $c-sales-review-action-display-sale-text
      background-color: $c-sales-review-action-display-sale-bg

      &:hover
        background-color: $c-sales-review-action-display-sale-hover-bg

    // $Sales Review action MOD: role main menu
    &_role_main-menu
      color: $c-sales-review-action-main-menu-text
      background-color: $c-sales-review-action-main-menu-bg

      &:hover
        background-color: $c-sales-review-action-main-menu-hover-bg

    // $Sales Review action MOD: role search
    &_role_search
      color: $c-sales-review-action-search-text
      background-color: $c-sales-review-action-search-bg

      &:hover
        background-color: $c-sales-review-action-search-hover-bg


  // $Sales Review version
  &__version
    position: absolute
    bottom: 7px
    left: 5px
    right: 5px
    display: block
    font-family: $f-sans
    font-size: 12px
    line-height: 1
    font-weight: 500
    color: $c-sales-review-version-text
    text-align: center

    +media-screen-1024x768
      bottom: 6px
      font-size: 11px

    +media-screen-1024x600
      bottom: 6px
      font-size: 11px
