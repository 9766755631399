.x-pos-loader-body
    background-color: #2fa081
    font-family: 'Montserrat', sans-serif
    width: 100%
    height: 100%

.x-pos-loader-container
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    padding: 10px
    width: 300px
    background-color: #ffffff
    color: #2fa081

.x-pos-loader-container h2
    margin: 20px 0 20px 0

.x-pos-loader-container p
    margin-top: 10px

.x-pos-loader-box
    text-align: center

.x-pos-loader-progressBar-outer
    width: 500px
    margin: 0 50px 0 50px
    background-color: #ffffff
    border: 1px solid #cccccc

.x-pos-loader-progressBar
    width: 0%
    height: 30px
    background-color: #2fa081
