.x-pos-auth-body
    background-color: #2fa081
    font-family: 'Montserrat', sans-serif
    display: flex
    align-items: center
    height: 100vh
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: 9999

.x-pos-auth-container
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    padding: 10px
    width: 600px
    background-color: #ffffff
    color: #2fa081

.x-pos-auth-container h1
    margin: 20px 0 20px 0

.x-pos-auth-container p
    margin-top: 10px
    margin-bottom: 10px

.x-pos-auth-box
    text-align: center

.x-pos-auth-progressBar-outer
    width: 500px
    margin: 0 50px 0 50px
    background-color: #ffffff
    border: 1px solid #cccccc

.x-pos-auth-progressBar
    width: 0%
    height: 30px
    background-color: #2fa081
