@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Sign Out
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-sign-out"

#{$block}
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  width: 100%
  height: 100%
  padding: 33px 60px 120px

  +media-screen-1280x800
    padding: 26px 60px 96px

  +media-screen-1024x768
    padding: 24px 48px 90px

  +media-screen-1024x600
    padding: 20px 48px 72px


  // $Sign Out header
  &__header
    display: block
    width: 100%


  // $Sign Out title
  &__title
    display: block
    font-family: $f-sans
    font-size: 56px
    line-height: 68px
    font-weight: 800
    color: $c-sign-out-title-text
    text-align: center

    +media-screen-1280x800
      font-size: 45px
      line-height: 54px

    +media-screen-1024x768
      font-size: 42px
      line-height: 51px

    +media-screen-1024x600
      font-size: 33px
      line-height: 41px


  // $Sign Out message
  &__message
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%


  // $Sign Out message text
  &__message-text
    display: block
    font-family: $f-sans
    font-size: 56px
    line-height: 68px
    font-weight: 800
    color: $c-sign-out-message-text
    text-align: center

    +media-screen-1280x800
      font-size: 45px
      line-height: 54px

    +media-screen-1024x768
      font-size: 42px
      line-height: 51px

    +media-screen-1024x600
      font-size: 33px
      line-height: 41px


  // $Sign Out actions
  &__actions
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%


  // $Sign Out action
  &__action
    display: block
    width: 280px
    height: 98px
    margin: 0
    padding: 0 5px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 30px
    line-height: 98px
    font-weight: 700
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 76px
      font-size: 24px
      line-height: 76px
      border-radius: 9px

    +media-screen-1024x768
      width: 224px
      height: 73px
      font-size: 22px
      line-height: 73px
      border-radius: 9px

    +media-screen-1024x600
      width: 224px
      height: 58px
      font-size: 20px
      line-height: 58px
      border-radius: 7px

    &:focus
      outline: none

    // $Sign Out action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Sign Out action MOD: role change fob
    &_role_change-fob
      color: $c-sign-out-action-change-fob-text
      background-color: $c-sign-out-action-change-fob-bg

      &:hover
        background-color: $c-sign-out-action-change-fob-hover-bg

    // $Sign Out action MOD: role confirm
    &_role_confirm
      color: $c-sign-out-action-confirm-text
      background-color: $c-sign-out-action-confirm-bg

      &:hover
        background-color: $c-sign-out-action-confirm-hover-bg

