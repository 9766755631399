@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Data
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-data"

#{$block}
  display: block
  width: 100%

  // $Data MOD: role transaction
  &_role_transaction

  // $Data MOD: role summary
  &_role_summary

  // $Data MOD: role sale
  &_role_sale


  // $Data list
  &__list
    display: block
    width: 100%


  // $Data item
  &__item
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%
    height: 50px

    // $Data item CONTEXT: $Data MOD: role summary
    #{$block}_role_summary &
      height: 100px

    // $Data item CONTEXT: $Data MOD: role sale
    #{$block}_role_sale &
      height: auto

    +media-screen-1280x800
      height: 40px

      #{$block}_role_summary &
        height: 80px

    +media-screen-1024x768
      height: 37px

      #{$block}_role_summary &
        height: 75px

    +media-screen-1024x600
      height: 30px

      #{$block}_role_summary &
        height: 60px

    // $Data item MOD: role total
    &_role_total
      height: 60px
      border-bottom: 3px solid $c-data-item-total-border

      #{$block}_role_summary &
        height: 140px
        border-bottom: none

      +media-screen-1280x800
        height: 45px

        #{$block}_role_summary &
          height: 105px

      +media-screen-1024x768
        height: 45px

        #{$block}_role_summary &
          height: 110px

      +media-screen-1024x600
        height: 36px

        #{$block}_role_summary &
          height: 80px

    // $Data item MOD: role balance
    // $Data item MOD: role change
    &_role_balance,
    &_role_change
      height: 70px

      // $Data item MOD: role balance CONTEXT: $Data MOD: role summary
      // $Data item MOD: role change CONTEXT: $Data MOD: role summary
      #{$block}_role_summary &
        height: 140px

      +media-screen-1280x800
        height: 52px

        #{$block}_role_summary &
          height: 110px

      +media-screen-1024x768
        height: 52px

        #{$block}_role_summary &
          height: 105px

      +media-screen-1024x600
        height: 42px

        #{$block}_role_summary &
          height: 80px

    &:not(:last-child)
      #{$block}_role_sale &
        margin-bottom: 45px

      +media-screen-1280x800
        #{$block}_role_sale &
          margin-bottom: 34px

      +media-screen-1024x768
        #{$block}_role_sale &
          margin-bottom: 34px

      +media-screen-1024x600
        #{$block}_role_sale &
          margin-bottom: 20px


  // $Data item label
  &__item-label
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 120px
    padding-right: 7px
    font-family: $f-sans
    font-size: 21px
    line-height: 1
    font-weight: 700
    text-transform: uppercase
    text-align: right

    // $Data item label CONTEXT: $Data MOD: role summary
    #{$block}_role_summary &
      width: 50%
      padding-right: 20px
      font-size: 60px

    // $Data item label CONTEXT: $Data MOD: role sale
    #{$block}_role_sale &
      padding-right: 12px
      font-size: 17px
      font-weight: 500
      color: $c-data-sale-item-label-text

    // $Data item label CONTEXT: $Data item MOD: role total
    #{$block}__item_role_total &
      font-size: 35px
      color: $c-data-item-label-total-text

    // $Data item label CONTEXT: $Data MOD: role summary $Data item MOD: role total
    #{$block}_role_summary #{$block}__item_role_total &
      font-size: 60px

    // $Data item label CONTEXT: $Data item MOD: role cash
    #{$block}__item_role_cash &
      color: $c-data-item-label-cash-text

    // $Data item label CONTEXT: $Data item MOD: role card
    #{$block}__item_role_card &
      color: $c-data-item-label-card-text

    // $Data item label CONTEXT: $Data item MOD: role loyalty
    #{$block}__item_role_loyalty &
      color: $c-data-item-label-loyalty-text

    // $Data item label CONTEXT: $Data item MOD: role deposit
    #{$block}__item_role_deposit &
      color: $c-data-item-label-deposit-text

    // $Data item label CONTEXT: $Data item MOD: role balance
    #{$block}__item_role_balance &
      color: $c-data-item-label-balance-text

    // $Data item label CONTEXT: $Data item MOD: role change
    #{$block}__item_role_change &
      color: $c-data-item-label-change-text

    +media-screen-1280x800
      font-size: 18px

      #{$block}__item_role_total &
        font-size: 26px

      #{$block}_role_summary &
        font-size: 50px

      #{$block}_role_summary #{$block}__item_role_total &
        font-size: 50px

    +media-screen-1024x768
      width: 96px
      padding-right: 5px
      font-size: 15px

      #{$block}__item_role_total &
        font-size: 26px

      #{$block}_role_summary &
        padding-right: 16px
        font-size: 45px

      #{$block}_role_summary #{$block}__item_role_total &
        font-size: 45px

      #{$block}_role_sale &
        padding-right: 10px
        font-size: 13px

    +media-screen-1024x600
      width: 96px
      padding-right: 5px
      font-size: 13px

      #{$block}__item_role_total &
        font-size: 21px

      #{$block}_role_summary &
        padding-right: 16px
        font-size: 36px

      #{$block}_role_summary #{$block}__item_role_total &
        font-size: 36px

      #{$block}_role_sale &
        padding-right: 10px
        font-size: 13px


  // $Data item value
  &__item-value
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: calc(100% - 120px)
    font-family: $f-sans
    font-size: 28px
    line-height: 1
    font-weight: 700
    text-align: center

    // $Data item value CONTEXT: $Data MOD: role summary
    #{$block}_role_summary &
      width: 50%
      font-size: 70px

    // $Data item value CONTEXT: $Data MOD: role sale
    #{$block}_role_sale &
      font-size: 17px
      font-weight: 500
      text-align: left

    // $Data item value CONTEXT: $Data item MOD: role total
    #{$block}__item_role_total &
      font-size: 40px
      color: $c-data-item-value-total-text

    // $Data item value CONTEXT: $Data MOD: role summary $Data item MOD: role total
    #{$block}_role_summary #{$block}__item_role_total &
      font-size: 80px

    // $Data item label CONTEXT: $Data item MOD: role total MOD: adjust split
    #{$block}__item_role_total#{$block}__item_adjust_split &
      font-size: 21px

    // $Data item value CONTEXT: $Data item MOD: role cash
    #{$block}__item_role_cash &
      color: $c-data-item-value-cash-text

    // $Data item value CONTEXT: $Data item MOD: role card
    #{$block}__item_role_card &
      color: $c-data-item-value-card-text

    // $Data item value CONTEXT: $Data item MOD: role loyalty
    #{$block}__item_role_loyalty &
      color: $c-data-item-value-loyalty-text

    // $Data item value CONTEXT: $Data item MOD: role deposit
    #{$block}__item_role_deposit &
      color: $c-data-item-value-deposit-text

    // $Data item value CONTEXT: $Data item MOD: role balance
    #{$block}__item_role_balance &
      height: 70px
      font-size: 40px
      line-height: 70px
      color: $c-data-item-value-balance-text
      border-radius: 12px
      background-color: $c-data-item-value-balance-bg

    // $Data item value STATE: active CONTEXT: $Data item MOD: role balance
    #{$block}__item_role_balance.is-active &
      background-color: $c-data-item-value-balance-active-bg

    // $Data item value CONTEXT: $Data item MOD: role change
    #{$block}__item_role_change &
      font-size: 40px
      color: $c-data-item-value-change-text

    // $Data item value CONTEXT: $Data MOD: role summary $Data item MOD: role change
    #{$block}_role_summary #{$block}__item_role_change &
      font-size: 80px

    // $Data item value STATE: active CONTEXT: $Data item MOD: role change
    #{$block}__item_role_change.is-active &
      background-color: $c-data-item-value-change-active-text

    +media-screen-1280x800
      font-size: 21px

      #{$block}__item_role_total &
        font-size: 32px

      #{$block}__item_role_total#{$block}__item_adjust_split &
        font-size: 18px

      #{$block}__item_role_balance &
        height: 52px
        font-size: 32px
        line-height: 52px
        border-radius: 9px

      #{$block}_role_summary &
        font-size: 55px

      #{$block}_role_summary #{$block}__item_role_total &,
      #{$block}_role_summary #{$block}__item_role_change &
        font-size: 65px

    +media-screen-1024x768
      width: calc(100% - 96px)
      font-size: 21px

      #{$block}__item_role_total &
        font-size: 30px

      #{$block}__item_role_total#{$block}__item_adjust_split &
        font-size: 15px

      #{$block}__item_role_balance &
        height: 52px
        font-size: 30px
        line-height: 52px
        border-radius: 9px

      #{$block}_role_summary &
        font-size: 52px

      #{$block}_role_summary #{$block}__item_role_total &,
      #{$block}_role_summary #{$block}__item_role_change &
        font-size: 60px

      #{$block}_role_sale &
        font-size: 13px

    +media-screen-1024x600
      width: calc(100% - 96px)
      font-size: 17px

      #{$block}__item_role_total &
        font-size: 24px

      #{$block}__item_role_total#{$block}__item_adjust_split &
        font-size: 13px

      #{$block}__item_role_balance &
        height: 42px
        font-size: 24px
        line-height: 42px
        border-radius: 7px

      #{$block}_role_summary &
        font-size: 42px

      #{$block}_role_summary #{$block}__item_role_total &,
      #{$block}_role_summary #{$block}__item_role_change &
        font-size: 48px

      #{$block}_role_sale &
        font-size: 13px

