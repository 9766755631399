@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Transaction
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-transaction"

#{$block}
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: stretch
  width: 100%
  height: 100%
  padding: 7px 48px 7px 26px

  +media-screen-1280x800
    padding: 6px 48px 6px 26px

  +media-screen-1024x768
    padding: 6px 38px 6px 20px

  +media-screen-1024x600
    padding: 6px 38px 6px 20px


  // $Transaction order
  &__order
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 402px
    height: 100%

    +media-screen-1024x768
      width: 320px

    +media-screen-1024x600
      width: 320px


  // $Transaction tender
  &__tender
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: stretch
    width: calc(100% - 402px)
    height: 100%

    +media-screen-1024x768
      width: calc(100% - 320px)

    +media-screen-1024x600
      width: calc(100% - 320px)


  // $Transaction header actions
  &__header-actions
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%
    margin-bottom: 7px

    +media-screen-1280x800
      margin-bottom: 6px

    +media-screen-1024x768
      margin-bottom: 6px

    +media-screen-1024x600
      margin-bottom: 6px


  // $Transaction header action
  &__header-action
    flex-grow: 1
    flex-shrink: 1
    display: block
    height: 48px
    margin: 0
    padding: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 21px
    line-height: 48px
    font-weight: 500
    color: $c-transaction-header-action-text
    text-align: center
    border-radius: 12px
    background-color: $c-transaction-header-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 35px
      font-size: 17px
      line-height: 35px
      border-radius: 9px

    +media-screen-1024x768
      height: 35px
      font-size: 16px
      line-height: 35px
      border-radius: 9px

    +media-screen-1024x600
      height: 30px
      font-size: 16px
      line-height: 30px
      border-radius: 7px

    &:hover
      background-color: $c-transaction-header-action-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 5px

    // $Transaction header action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Transaction invoice
  &__invoice
    display: block
    width: 100%
    margin-bottom: 7px

    +media-screen-1024x768
      margin-bottom: 6px

    +media-screen-1024x600
      margin-bottom: 6px


  // $Transaction pagination
  &__pagination
    display: block
    width: 100%
    margin-bottom: 26px

    +media-screen-1280x800
      margin-bottom: 21px

    +media-screen-1024x768
      margin-bottom: 20px

    +media-screen-1024x600
      margin-bottom: 15px


  // $Transaction actions
  &__actions
    display: block
    width: 280px
    margin: 0 auto

    +media-screen-1024x768
      width: 224px

    +media-screen-1024x600
      width: 224px


  // $Transaction action
  &__action
    display: block
    width: 100%
    height: 98px
    margin: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 50px
    line-height: 98px
    font-weight: 700
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 76px
      font-size: 39px
      line-height: 76px
      border-radius: 9px

    +media-screen-1024x768
      height: 73px
      font-size: 37px
      line-height: 73px
      border-radius: 9px

    +media-screen-1024x600
      height: 58px
      font-size: 30px
      line-height: 58px
      border-radius: 7px

    &:focus
      outline: none

    // $Transaction action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Transaction action MOD: role split
    &_role_split
      margin-bottom: 32px
      color: $c-transaction-action-split-text
      background-color: $c-transaction-action-split-bg

      +media-screen-1280x800
        margin-bottom: 25px

      +media-screen-1024x768
        margin-bottom: 24px

      +media-screen-1024x600
        margin-bottom: 19px

      &:hover
        background-color: $c-transaction-action-split-hover-bg

    // $Transaction action MOD: role receipt
    &_role_receipt
      color: $c-transaction-action-receipt-text
      background-color: $c-transaction-action-receipt-bg

      &:hover
        background-color: $c-transaction-action-receipt-hover-bg


  // $Transaction calculation
  &__calculation
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: calc(100% - 280px)
    padding: 0 78px 0 58px

    +media-screen-1024x768
      width: calc(100% - 224px)
      padding: 0 62px 0 46px

    +media-screen-1024x600
      width: calc(100% - 224px)
      padding: 0 62px 0 46px


  // $Transaction data
  &__data
    display: block
    width: 100%
    margin-bottom: 7px

    +media-screen-1280x800
      margin-bottom: 6px

    +media-screen-1024x768
      margin-bottom: 6px

    +media-screen-1024x600
      margin-bottom: 6px


  // $Transaction numpad
  &__numpad
    display: block
    width: 100%


  // $Transaction options
  &__options
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 280px
    padding-top: 103px

    +media-screen-1280x800
      padding-top: 80px

    +media-screen-1024x768
      width: 224px
      padding-top: 77px

    +media-screen-1024x600
      width: 224px
      padding-top: 61px


  // $Transaction denominations
  &__denominations
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    width: 100%
    margin-bottom: 80px

    +media-screen-1280x800
      margin-bottom: 62px

    +media-screen-1024x768
      margin-bottom: 60px

    +media-screen-1024x600
      margin-bottom: 48px


  // $Transaction denomination
  &__denomination
    display: block
    width: 100%
    height: 98px
    margin: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 50px
    line-height: 98px
    font-weight: 700
    text-align: center
    text-transform: uppercase
    color: $c-transaction-denomination-text
    border-radius: 12px
    background-color: $c-transaction-denomination-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 76px
      font-size: 39px
      line-height: 76px
      border-radius: 9px

    +media-screen-1024x768
      height: 73px
      font-size: 37px
      line-height: 73px
      border-radius: 9px

    +media-screen-1024x600
      height: 58px
      font-size: 30px
      line-height: 58px
      border-radius: 7px

    &:hover
      background-color: $c-transaction-denomination-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-bottom: 21px

      +media-screen-1280x800
        margin-bottom: 18px

      +media-screen-1024x768
        margin-bottom: 15px

      +media-screen-1024x600
        margin-bottom: 13px

    // $Transaction denomination STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Transaction methods
  &__methods
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    width: 100%


  // $Transaction method
  &__method
    display: block
    width: 100%
    height: 98px
    margin: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 50px
    line-height: 98px
    font-weight: 700
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 76px
      font-size: 39px
      line-height: 76px
      border-radius: 9px

    +media-screen-1024x768
      height: 73px
      font-size: 37px
      line-height: 73px
      border-radius: 9px

    +media-screen-1024x600
      height: 58px
      font-size: 30px
      line-height: 58px
      border-radius: 7px

    &:focus
      outline: none

    // $Transaction method STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Transaction action MOD: role cash
    &_role_cash
      margin-bottom: 60px
      color: $c-transaction-method-cash-text
      background-color: $c-transaction-method-cash-bg

      +media-screen-1280x800
        margin-bottom: 48px

      +media-screen-1024x768
        margin-bottom: 46px

      +media-screen-1024x600
        margin-bottom: 26px

      &:hover
        background-color: $c-transaction-method-cash-hover-bg

    // $Transaction action MOD: role card
    &_role_card
      margin-bottom: 60px
      color: $c-transaction-method-card-text
      background-color: $c-transaction-method-card-bg

      +media-screen-1280x800
        margin-bottom: 48px

      +media-screen-1024x768
        margin-bottom: 46px

      +media-screen-1024x600
        margin-bottom: 26px

      &:hover
        background-color: $c-transaction-method-card-hover-bg

    // $Transaction action MOD: role deposit
    &_role_deposit
      color: $c-transaction-method-deposit-text
      background-color: $c-transaction-method-deposit-bg

      &:hover
        background-color: $c-transaction-method-deposit-hover-bg
