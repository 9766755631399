////////////////////////////////////////
// Common styles
////////////////////////////////////////

////////////////////////////////////////Variables

// Colors
// $c-selection_cl: #fff
// $c-selection_bg: #000


//////////////////////////////////////// Mixins and functions


//////////////////////////////////////// Styles

// *::selection
//  background: $c-selection_bg
//  color: $c-selection_cl

// *::-moz-selection
//  background: $c-selection_bg
//  color: $c-selection_cl


html
  margin: 0
  padding: 0
  overflow: auto


body
  margin: 0
  padding: 0
  background-color: $c-body-bg


.iScrollHorizontalScrollbar,
.iScrollVerticalScrollbar,
.iScrollLoneScrollbar
  z-index: 100 !important


