@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Popup
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-popup"

#{$block}
  flex-grow: 0
  flex-shrink: 0
  display: block
  width: 400px
  height: 400px
  margin: auto
  border-radius: 15px
  background-color: $c-popup-bg

  +media-screen-1280x800
    height: 320px
    border-radius: 12px

  +media-screen-1024x768
    width: 320px
    height: 300px
    border-radius: 12px

  +media-screen-1024x600
    width: 320px
    height: 240px
    border-radius: 9px

  // $Popup MOD: role info
  &_role_info

  // $Popup MOD: role success
  &_role_success

  // $Popup MOD: role error
  &_role_error
    height: auto
    min-height: 400px
    width: 600px

    +media-screen-1280x800
      min-height: 320px

    +media-screen-1024x768
      width: 480px
      min-height: 300px

    +media-screen-1024x600
      width: 480px
      min-height: 240px

  // $Popup MOD: role confirmation
  // $Popup MOD: role redeem deposit
  &_role_confirmation,
  &_role_redeem-deposit
    width: 664px
    height: 378px

    +media-screen-1280x800
      height: 302px

    +media-screen-1024x768
      width: 532px
      height: 283px

    +media-screen-1024x600
      width: 532px
      height: 226px


  // $Popup content
  &__content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    padding: 20px

    // $Popup content CONTEXT: $Popup MOD: role confirmation
    #{$block}_role_confirmation &
      padding: 35px 30px 40px

    // $Popup content CONTEXT: $Popup MOD: role redeem deposit
    #{$block}_role_redeem-deposit &
      padding: 20px 30px 40px

    +media-screen-1280x800
      padding: 16px 20px

      #{$block}_role_confirmation &
        padding: 28px 30px 32px

      #{$block}_role_redeem-deposit &
        padding: 16px 30px 32px

    +media-screen-1024x768
      padding: 15px 16px

      #{$block}_role_confirmation &
        padding: 26px 24px 30px

      #{$block}_role_redeem-deposit &
        padding: 15px 24px 30px

    +media-screen-1024x600
      padding: 12px 16px

      #{$block}_role_confirmation &
        padding: 21px 24px 24px

      #{$block}_role_redeem-deposit &
        padding: 12px 24px 24px


  // $Popup icon
  &__icon
    flex-grow: 0
    flex-shrink: 0
    display: block
    margin-bottom: 20px

    +media-screen-1280x800
      margin-bottom: 16px

    +media-screen-1024x768
      margin-bottom: 15px

    +media-screen-1024x600
      margin-bottom: 12px

    &:before
      content: ''
      display: block
      font-family: icons
      font-size: 100px
      line-height: 100px
      font-weight: 400
      color: $c-popup-icon

      +media-screen-1280x800
        font-size: 80px
        line-height: 80px

      +media-screen-1024x768
        font-size: 75px
        line-height: 75px

      +media-screen-1024x600
        font-size: 60px
        line-height: 60px

      // $Popup icon CONTEXT: $Popup MOD: role info
      #{$block}_role_info &
        content: '\e91d'
        color: $c-popup-info-icon

      // $Popup icon CONTEXT: $Popup MOD: role success
      #{$block}_role_success &
        content: '\e91e'
        color: $c-popup-success-icon

      // $Popup icon CONTEXT: $Popup MOD: role error
      #{$block}_role_error &
        content: '\e91f'
        color: $c-popup-error-icon


  // $Popup info
  &__info
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: 100%


  // $Popup title
  &__title
    display: block
    width: 100%
    margin-bottom: 20px
    font-family: $f-sans
    font-size: 40px
    line-height: 1
    font-weight: 700
    color: $c-popup-title-text
    text-align: center

    // $Popup title CONTEXT: $Popup MOD: role confirmation
    #{$block}_role_confirmation &
      margin-bottom: 30px
      font-size: 72px
      color: $c-popup-confirmation-title-text
      text-transform: uppercase

    // $Popup title CONTEXT: $Popup MOD: role redeem deposit
    #{$block}_role_redeem-deposit &
      margin-bottom: 30px
      font-size: 49px
      color: $c-popup-redeem-deposit-title-text
      text-transform: uppercase

    +media-screen-1280x800
      margin-bottom: 16px
      font-size: 32px

      #{$block}_role_confirmation &
        margin-bottom: 24px
        font-size: 56px

      #{$block}_role_redeem-deposit &
        margin-bottom: 24px
        font-size: 39px

    +media-screen-1024x768
      margin-bottom: 15px
      font-size: 30px

      #{$block}_role_confirmation &
        margin-bottom: 22px
        font-size: 54px

      #{$block}_role_redeem-deposit &
        margin-bottom: 22px
        font-size: 36px

    +media-screen-1024x600
      margin-bottom: 12px
      font-size: 24px

      #{$block}_role_confirmation &
        margin-bottom: 18px
        font-size: 44px

      #{$block}_role_redeem-deposit &
        margin-bottom: 18px
        font-size: 29px


  // $Popup label
  &__label
    display: block
    width: 100%
    margin-bottom: 20px
    font-family: $f-sans
    font-size: 32px
    line-height: 1
    font-weight: 700
    color: $c-popup-label-text
    text-align: center

    // $Popup label CONTEXT: $Popup MOD: role confirmation
    #{$block}_role_confirmation &
      color: $c-popup-confirmation-label-text
      text-transform: uppercase

    // $Popup title CONTEXT: $Popup MOD: role redeem deposit
    #{$block}_role_redeem-deposit &
      font-size: 42px
      color: $c-popup-redeem-deposit-label-text
      text-transform: uppercase

    +media-screen-1280x800
      margin-bottom: 16px

      #{$block}_role_confirmation &
        font-size: 26px

      #{$block}_role_redeem-deposit &
        font-size: 33px

    +media-screen-1024x768
      font-size: 15px

      #{$block}_role_confirmation &
        font-size: 24px

      #{$block}_role_redeem-deposit &
        font-size: 31px

    +media-screen-1024x600
      font-size: 12px

      #{$block}_role_confirmation &
        font-size: 20px

      #{$block}_role_redeem-deposit &
        font-size: 25px


  // $Popup text
  &__text
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 20px
    line-height: 1.25
    font-weight: 500
    color: $c-popup-text
    text-align: center

    // $Popup text CONTEXT: $Popup MOD: role error
    #{$block}_role_error &
      min-height: 100px
      margin-bottom: 20px

    // $Popup text CONTEXT: $Popup MOD: role confirmation
    #{$block}_role_confirmation &
      font-size: 32px
      font-weight: 700
      color: $c-popup-confirmation-text
      text-transform: uppercase

    // $Popup text CONTEXT: $Popup MOD: role redeem deposit
    #{$block}_role_redeem-deposit &
      font-size: 63px
      line-height: 1
      font-weight: 700
      color: $c-popup-redeem-deposit-text
      text-transform: uppercase

    +media-screen-1280x800
      margin-bottom: 16px

      #{$block}_role_error &
        min-height: 76px

      #{$block}_role_confirmation &
        font-size: 26px

      #{$block}_role_redeem-deposit &
        font-size: 49px

    +media-screen-1024x768
      font-size: 15px

      #{$block}_role_error &
        min-height: 70px

      #{$block}_role_confirmation &
        font-size: 24px

      #{$block}_role_redeem-deposit &
        font-size: 46px

    +media-screen-1024x600
      font-size: 12px

      #{$block}_role_error &
        min-height: 52px

      #{$block}_role_confirmation &
        font-size: 20px

      #{$block}_role_redeem-deposit &
        font-size: 40px

    &:not(:last-child)
      margin-bottom: 10px

      +media-screen-1280x800
        margin-bottom: 9px

      +media-screen-1024x768
        margin-bottom: 8px

      +media-screen-1024x600
        margin-bottom: 6px


  // $Popup actions
  &__actions
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%


  // $Popup action
  &__action
    flex-grow: 0
    flex-shrink: 1
    display: block
    width: 50%
    height: 60px
    margin: 0
    padding: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 20px
    line-height: 60px
    font-weight: 700
    color: $c-popup-action-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-popup-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    // $Popup action CONTEXT: $Popup MOD: role confirmation
    // $Popup action CONTEXT: $Popup MOD: role redeem deposit
    #{$block}_role_confirmation &,
    #{$block}_role_redeem-deposit &
      width: 175px
      height: 63px
      font-size: 35px
      line-height: 63px

    +media-screen-1280x800
      height: 48px
      font-size: 16px
      line-height: 48px
      border-radius: 9px

      #{$block}_role_confirmation &,
      #{$block}_role_redeem-deposit &
        height: 50px
        font-size: 31px
        line-height: 50px

    +media-screen-1024x768
      height: 45px
      font-size: 15px
      line-height: 45px
      border-radius: 9px

      #{$block}_role_confirmation &,
      #{$block}_role_redeem-deposit &
        width: 140px
        height: 46px
        font-size: 27px
        line-height: 46px

    +media-screen-1024x600
      height: 36px
      font-size: 12px
      line-height: 36px
      border-radius: 7px

      #{$block}_role_confirmation &,
      #{$block}_role_redeem-deposit &
        width: 140px
        height: 40px
        font-size: 21px
        line-height: 40px

    &:hover
      background-color: $c-popup-action-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 20px

      #{$block}_role_confirmation &,
      #{$block}_role_redeem-deposit &
        margin-right: 70px

      +media-screen-1024x768
        margin-right: 16px

        #{$block}_role_confirmation &,
        #{$block}_role_redeem-deposit &
          margin-right: 56px

      +media-screen-1024x600
        margin-right: 16px

        #{$block}_role_confirmation &,
        #{$block}_role_redeem-deposit &
          margin-right: 56px

    // $Popup action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Popup action MOD: role retry
    &_role_retry
      background-color: $c-popup-action-retry-bg
      color: $c-popup-action-retry-text

      &:hover
        background-color: $c-popup-action-retry-hover-bg

    // $Popup action MOD: role no
    &_role_no
      background-color: $c-popup-action-no-bg
      color: $c-popup-action-no-text

      &:hover
        background-color: $c-popup-action-no-hover-bg

    // $Popup action MOD: role yes
    &_role_yes
      background-color: $c-popup-action-yes-bg
      color: $c-popup-action-yes-text

      &:hover
        background-color: $c-popup-action-yes-hover-bg
