@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Field
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-field"

#{$block}
  display: block
  width: 100%

  // $Field MOD: role search
  &_role_search

  // $Field MOD: role pin
  &_role_pin

  // $Field MOD: role fob
  &_role_fob

  // $Field group
  &__group
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: stretch
    width: 100%


  // $Field box
  &__box
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: auto
    height: 40px
    padding: 0 12px
    font-family: $f-sans
    font-size: 21px
    line-height: 38px
    font-weight: 500
    color: $c-field-box-text
    white-space: nowrap
    border: 1px solid $c-field-box-border
    border-radius: 7px
    background-color: $c-field-box-bg
    overflow: hidden

    // $Field box CONTEXT: $Field MOD: role pin
    #{$block}_role_pin &
      padding: 10px 12px
      font-size: 50px

    +media-screen-1280x800
      height: 32px
      font-size: 18px
      line-height: 30px
      border-radius: 5px

      #{$block}_role_pin &
        padding: 8px 12px
        font-size: 40px

    +media-screen-1024x768
      height: 30px
      padding: 0 10px
      font-size: 15px
      line-height: 28px
      border-radius: 5px

      #{$block}_role_pin &
        padding: 7px 9px
        font-size: 37px

    +media-screen-1024x600
      height: 24px
      padding: 0 10px
      font-size: 15px
      line-height: 22px
      border-radius: 5px

      #{$block}_role_pin &
        padding: 6px 9px
        font-size: 30px

    &:not(:last-child)
      margin-right: 12px

      +media-screen-1024x768
        margin-right: 10px

      +media-screen-1024x600
        margin-right: 10px


  // $Field action
  &__action
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 140px
    height: 40px
    margin: 0
    padding: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 21px
    line-height: 40px
    font-weight: 700
    color: $c-field-action-text
    text-align: center
    border-radius: 7px
    background-color: $c-field-action-bg
    text-transform: uppercase
    cursor: pointer
    user-select: none
    transition: all .2s

    // $Field action CONTEXT: $Field MOD: role pin
    #{$block}_role_pin &
      font-size: 0
      line-height: 0
      color: transparent
      background-color: $c-field-action-enter-bg

    +media-screen-1280x800
      height: 32px
      font-size: 18px
      line-height: 32px
      border-radius: 5px

    +media-screen-1024x768
      width: 112px
      height: 30px
      font-size: 15px
      line-height: 30px
      border-radius: 5px

    +media-screen-1024x600
      width: 112px
      height: 24px
      font-size: 15px
      line-height: 24px
      border-radius: 5px

    &:hover
      background-color: $c-field-action-hover-bg

      #{$block}_role_pin &
        background-color: $c-field-action-enter-hover-bg

    &:focus
      outline: none

    &:before
      #{$block}_role_pin &
        content: '\e902'
        display: inline-block
        font-family: icons
        font-size: 24px
        line-height: 24px
        color: $c-field-action-enter-icon

      +media-screen-1280x800
        #{$block}_role_pin &
          font-size: 19px
          line-height: 19px

      +media-screen-1024x768
        #{$block}_role_pin &
          font-size: 18px
          line-height: 18px

      +media-screen-1024x600
        #{$block}_role_pin &
          font-size: 15px
          line-height: 15px

    // $Field action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none
