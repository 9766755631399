.pos-qr-scanner
  margin-top: 20px
  display: block
  video
    width: 100%
    height: 100%

.pos-loyalty-error
  margin-top: 127px

.pos-sign-in__server
  margin-top: 15px

.loyalty-failed
  font-family: Montserrat
  color: red
  font-size: 24px
  font-weight: bold
  padding: 20px