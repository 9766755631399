@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Numpad
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-numpad"

#{$block}
  display: block
  width: 100%

  // $Numpad MOD: role main
  &_role_main

  // $Numpad MOD: role transaction
  &_role_transaction

  // $Numpad MOD: role sign-in
  &_role_sign-in

  // $Numpad MOD: role new-table
  &_role_new-table

  // $Numpad MOD: role transfer-table
  &_role_transfer-table


  // $Numpad display
  &__display
    display: block
    width: 100%
    height: 60px
    padding: 0 12px
    font-family: $f-sans
    color: $c-numpad-display-text
    font-size: 40px
    line-height: 50px
    font-weight: 700
    text-align: right
    white-space: nowrap
    border: 5px solid $c-numpad-display-border
    border-radius: 12px
    background-color: $c-numpad-display-bg

    // $Numpad display CONTEXT: $Numpad MOD: role transaction
    #{$block}_role_transaction &
      height: 98px
      padding: 0 12px
      font-size: 60px
      line-height: 88px
      color: $c-numpad-transaction-display-text
      border-color: $c-numpad-transaction-display-border
      background-color: $c-numpad-transaction-display-bg

    +media-screen-1280x800
      height: 45px
      font-size: 30px
      line-height: 37px
      border-width: 4px
      border-radius: 9px

      #{$block}_role_transaction &
        height: 73px
        font-size: 45px
        line-height: 65px

    +media-screen-1024x768
      height: 45px
      padding: 0 8px
      font-size: 30px
      line-height: 37px
      border-width: 4px
      border-radius: 9px

      #{$block}_role_transaction &
        height: 73px
        font-size: 45px
        line-height: 65px

    +media-screen-1024x600
      height: 36px
      padding: 0 8px
      font-size: 24px
      line-height: 28px
      border-width: 4px
      border-radius: 7px

      #{$block}_role_transaction &
        height: 58px
        font-size: 36px
        line-height: 50px

    &:not(:last-child)
      margin-bottom: 7px

      #{$block}_role_transaction &
        margin-bottom: 10px

      +media-screen-1280x800
        margin-bottom: 7px

        #{$block}_role_transaction &
          margin-bottom: 8px

      +media-screen-1024x768
        margin-bottom: 7px

        #{$block}_role_transaction &
          margin-bottom: 6px

      +media-screen-1024x600
        margin-bottom: 6px

        #{$block}_role_transaction &
          margin-bottom: 6px


  // $Numpad controls
  &__controls
    display: block
    width: 100%


  // $Numpad controls row
  &__controls-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%

    &:not(:last-child)
      margin-bottom: 7px

      #{$block}_role_transaction &
        margin-bottom: 14px

      +media-screen-1280x800
        margin-bottom: 6px

        #{$block}_role_transaction &
          margin-bottom: 10px

      +media-screen-1024x768
        margin-bottom: 6px

        #{$block}_role_transaction &
          margin-bottom: 8px

      +media-screen-1024x600
        margin-bottom: 6px

        #{$block}_role_transaction &
          margin-bottom: 8px


  // $Numpad control
  &__control
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: 33.333%
    height: 70px
    margin: 0
    padding: 0
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 50px
    line-height: 70px
    font-weight: 500
    color: $c-numpad-control-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-numpad-control-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    // $Numpad control CONTEXT: $Numpad MOD: role transaction
    #{$block}_role_transaction &
      height: 120px
      font-size: 70px
      line-height: 120px
      color: $c-numpad-transaction-control-text
      background-color: $c-numpad-transaction-control-bg

    // $Numpad control CONTEXT: $Numpad MOD: role sign-in
    #{$block}_role_sign-in &
      color: $c-numpad-sign-in-control-text
      background-color: $c-numpad-sign-in-control-bg

    // $Numpad control CONTEXT: $Numpad MOD: role new-table
    #{$block}_role_new-table &
      color: $c-numpad-new-table-control-text
      background-color: $c-numpad-new-table-control-bg

    // $Numpad control CONTEXT: $Numpad MOD: role transfer-table
    #{$block}_role_transfer-table &
      color: $c-numpad-transfer-table-control-text
      background-color: $c-numpad-transfer-table-control-bg

    +media-screen-1280x800
      height: 50px
      font-size: 38px
      line-height: 50px
      border-radius: 9px

      #{$block}_role_transaction &
        height: 94px
        font-size: 54px
        line-height: 94px

    +media-screen-1024x768
      height: 50px
      font-size: 38px
      line-height: 50px
      border-radius: 9px

      #{$block}_role_transaction &
        height: 90px
        font-size: 52px
        line-height: 90px

    +media-screen-1024x600
      height: 40px
      font-size: 30px
      line-height: 40px
      border-radius: 7px

      #{$block}_role_transaction &
        height: 72px
        font-size: 42px
        line-height: 72px

    &:hover
      background-color: $c-numpad-control-hover-bg

      #{$block}_role_transaction &
        background-color: $c-numpad-transaction-control-hover-bg

      #{$block}_role_sign-in &
        background-color: $c-numpad-sign-in-control-hover-bg

      #{$block}_role_new-table &
        background-color: $c-numpad-new-table-control-hover-bg

      #{$block}_role_transfer-table &
        background-color: $c-numpad-transfer-table-control-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 12px

      #{$block}_role_transaction &
        margin-right: 14px

      +media-screen-1280x800
        margin-right: 10px

        #{$block}_role_transaction &
          margin-right: 10px

      +media-screen-1024x768
        margin-right: 10px

        #{$block}_role_transaction &
          margin-right: 8px

      +media-screen-1024x600
        margin-right: 10px

        #{$block}_role_transaction &
          margin-right: 8px

    // $Numpad control MOD: role clear
    &_role_clear
      width: calc(66.666% + 12px)
      color: $c-numpad-control-clear-text
      line-height: 60px
      font-weight: 700
      border: 5px solid $c-numpad-control-clear-border
      background-color: $c-numpad-control-clear-bg

      // $Numpad control MOD: role clear CONTEXT: $Numpad MOD: role transaction
      #{$block}_role_transaction &
        width: calc(66.666% + 14px)
        line-height: 110px
        color: $c-numpad-transaction-control-clear-text
        border-color: $c-numpad-transaction-control-clear-border
        background-color: $c-numpad-transaction-control-clear-bg

      // $Numpad control MOD: role clear CONTEXT: $Numpad MOD: role sign-in
      #{$block}_role_sign-in &
        color: $c-numpad-sign-in-control-clear-text
        border-color: $c-numpad-sign-in-control-clear-border
        background-color: $c-numpad-sign-in-control-clear-bg

      // $Numpad control MOD: role clear CONTEXT: $Numpad MOD: role new-table
      #{$block}_role_new-table &
        color: $c-numpad-new-table-control-clear-text
        border-color: $c-numpad-new-table-control-clear-border
        background-color: $c-numpad-new-table-control-clear-bg

      // $Numpad control MOD: role clear CONTEXT: $Numpad MOD: role transfer-table
      #{$block}_role_transfer-table &
        color: $c-numpad-transfer-table-control-clear-text
        border-color: $c-numpad-transfer-table-control-clear-border
        background-color: $c-numpad-transfer-table-control-clear-bg

      +media-screen-1280x800
        width: calc(66.666% + 10px)
        line-height: 42px
        border-width: 4px

        #{$block}_role_transaction &
          width: calc(66.666% + 10px)
          line-height: 86px
          border-width: 4px

      +media-screen-1024x768
        width: calc(66.666% + 10px)
        line-height: 42px
        border-width: 4px

        #{$block}_role_transaction &
          width: calc(66.666% + 8px)
          line-height: 82px
          border-width: 4px

      +media-screen-1024x600
        width: calc(66.666% + 10px)
        line-height: 32px
        border-width: 4px

        #{$block}_role_transaction &
          width: calc(66.666% + 8px)
          line-height: 64px
          border-width: 4px

      &:hover
        color: $c-numpad-control-clear-hover-text
        background-color: $c-numpad-control-clear-hover-bg

        #{$block}_role_transaction &
          color: $c-numpad-transaction-control-clear-hover-text
          background-color: $c-numpad-transaction-control-clear-hover-bg

        #{$block}_role_sign-in &
          color: $c-numpad-sign-in-control-clear-hover-text
          background-color: $c-numpad-sign-in-control-clear-hover-bg

        #{$block}_role_new-table &
          color: $c-numpad-new-table-control-clear-hover-text
          background-color: $c-numpad-new-table-control-clear-hover-bg

        #{$block}_role_transfer-table &
          color: $c-numpad-transfer-table-control-clear-hover-text
          background-color: $c-numpad-transfer-table-control-clear-hover-bg

