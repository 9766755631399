.is-table-in-use
  background-color: #c17a7a !important

.pos-tables__arrow-right
  position: absolute
  top: calc((100% - 300px) / 2)
  right: 0
  height: 300px
  width: 50px

.pos-tables__arrow-right-button
  height: 100%
  width: 100%
  border-bottom-left-radius: 15px
  border-top-left-radius: 15px
  background-color: #d06101
  color: #ffffff
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif
  text-orientation: upright
  writing-mode: vertical-lr
  font-size: 22px
  font-weight: bold
  border: none

.pos-tables__arrow-right-button:disabled,
.pos-tables__arrow-right-button[disabled],
.pos-tables__arrow-right-button.is-disabled
  cursor: default
  opacity: .4
  pointer-events: none

.pos-tables__arrow-left
  position: absolute
  top: calc((100% - 300px) / 2)
  left: 0
  height: 300px
  width: 50px

.pos-tables__arrow-left-button
  height: 100%
  width: 100%
  border-bottom-right-radius: 15px
  border-top-right-radius: 15px
  background-color: #d06101
  color: #ffffff
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif
  text-orientation: upright
  writing-mode: vertical-lr
  font-size: 22px
  font-weight: bold
  border: none

.pos-tables__arrow-left-button:hover
  background-color: darken(#d06101, 20%)


.pos-tables__arrow-left-button:disabled,
.pos-tables__arrow-left-button[disabled],
.pos-tables__arrow-left-button.is-disabled
  cursor: default
  opacity: .4
  pointer-events: none


.pos-tables__list
  margin-top: 50px !important