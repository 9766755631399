////////////////////////////////////////
// Project variables
////////////////////////////////////////

//////////////////////////////////////// Breakpoints

//////////////////////////////////////// Colors

$c-body-bg: #fffce6

$c-modal-bg: #fdfbed
$c-modal-border: #707070
$c-modal-overlay-bg: transparentize(#000000,.2)
$c-modal-overlay-light-bg: transparentize(#fdfbed, 0.2)

$c-popup-bg: #fdfbed
$c-popup-icon: #535151
$c-popup-title-text: #000000
$c-popup-label-text: #000000
$c-popup-text: #000000
$c-popup-action-bg: #969595
$c-popup-action-hover-bg: darken(#969595, 20%)
$c-popup-action-text: #fdfbed
$c-popup-action-retry-bg: #2e6b98
$c-popup-action-retry-hover-bg: darken(#2e6b98, 20%)
$c-popup-action-retry-text: #fdfbed
$c-popup-action-no-bg: #c17a7a
$c-popup-action-no-hover-bg: darken(#c17a7a, 20%)
$c-popup-action-no-text: #fdfbed
$c-popup-action-yes-bg: #7ac17a
$c-popup-action-yes-hover-bg: darken(#7ac17a, 20%)
$c-popup-action-yes-text: #fdfbed
$c-popup-info-icon: #2e6b98
$c-popup-success-icon: #109541
$c-popup-error-icon: #9f0101
$c-popup-confirmation-title-text: #646464
$c-popup-confirmation-label-text: #a2a2a2
$c-popup-confirmation-text: #6e6b6b
$c-popup-redeem-deposit-title-text: #646464
$c-popup-redeem-deposit-label-text: #a2a2a2
$c-popup-redeem-deposit-text: #6e6b6b

$c-loader-light-message-text: transparentize(#ffffff, 0.2)
$c-loader-dark-message-text: transparentize(#000000, 0.2)
$c-loader-light-label-text: transparentize(#ffffff, 0.4)
$c-loader-dark-label-text: transparentize(#000000, 0.4)

$c-header-welcome-bg: #6984a3
$c-header-sales-review-bg: #2fa081
$c-header-info-bg: #6984a3
$c-header-label-text: #fdfbed
$c-header-title-text: #fdfbed
$c-header-sidebar-toggle-icon: #fdfbed
$c-header-sidebar-toggle-hover-icon: darken(#6984a3, 25%)
$c-header-menu-toggle-icon: #fdfbed
$c-header-menu-toggle-hover-icon: darken(#6984a3, 25%)
$c-header-search-toggle-bg: #fdfbed
$c-header-search-toggle-text: #6984a3
$c-header-search-toggle-icon: #7496c3
$c-header-search-toggle-hover-bg: darken(#6984a3, 25%)
$c-header-search-toggle-hover-text: #fdfbed
$c-header-search-toggle-hover-icon: #fdfbed
$c-header-action-bg: #6984a3
$c-header-action-hover-bg: darken(#6984a3, 20%)
$c-header-action-text: #fdfbed

$c-sidebar-overlay-bg: transparentize(#000000,.2)
$c-sidebar-bg: #6984a3
$c-sidebar-toggle-icon: #fffce6
$c-sidebar-menu-action-bg: transparent
$c-sidebar-menu-action-border: #fffce6
$c-sidebar-menu-action-text: #fffce6
$c-sidebar-menu-action-hover-bg: transparentize(#000000,.7)
$c-sidebar-menu-action-hover-text: #fffce6
$c-sidebar-menu-action-active-bg: transparentize(#000000,.7)
$c-sidebar-menu-action-active-text: #fffce6

$c-main-version-text: transparentize(#000000, 0.5)
$c-main-action-bg: #770c80
$c-main-action-hover-bg: darken(#770c80, 10%)
$c-main-action-text: #fdfbed
$c-main-subtotal-bg: #80a080
$c-main-subtotal-hover-bg: darken(#80a080, 20%)
$c-main-subtotal-text: #fff

$c-menu-category-bg: #2fa081
$c-menu-category-text: #fdfbed
$c-menu-category-icon: #fdfbed
$c-menu-category-hover-bg: #1c5243
$c-menu-category-active-bg: #1c5243
$c-menu-category-active-corner: #ff7f50
$c-menu-category-home-bg: #c76f85
$c-menu-category-home-active-bg: #000
$c-menu-category-home-active-corner: #ff7f50
$c-menu-subcategories-bg: #d5ead2
$c-menu-subcategories-control-bg: #2e6b98
$c-menu-subcategories-control-icon: #fdfbed
$c-menu-subcategories-control-hover-bg: darken(#2e6b98, 15%)
$c-menu-subcategory-bg: #8e8e8e
$c-menu-subcategory-text: #fdfbed
$c-menu-subcategory-hover-bg: darken(#8e8e8e, 20%)
$c-menu-subcategory-active-corner: #ff7f50
$c-menu-items-control-bg: transparent
$c-menu-items-control-border: #044c4c
$c-menu-items-control-icon: #044c4c
$c-menu-items-control-hover-bg: #044c4c
$c-menu-items-control-hover-icon: #fdfbed
$c-menu-item-bg: #8e8e8e
$c-menu-item-text: #fdfbed
$c-menu-item-meta-text: transparentize(#fdfbed, 0.5)
$c-menu-item-hover-bg: darken(#8e8e8e, 20%)

$c-invoice-panel-bg: #fdfbed
$c-invoice-panel-border: #707070
$c-invoice-panel-header-bg: #c17a7a
$c-invoice-panel-title-text: #fdfbed
$c-invoice-panel-footer-bg: #eae8d9
$c-invoice-panel-deposit-bg: #e2ead9
$c-invoice-panel-deposit-text: #62315b
$c-invoice-panel-function-text: #023e18
$c-invoice-list-label-text: #a0a0a0
$c-invoice-item-bg: transparent
$c-invoice-item-text: #000000
$c-invoice-item-meta-text: #726f6f
$c-invoice-item-meta-label-text: #726f6f
$c-invoice-item-meta-label-take-off-text: #84ac6c
$c-invoice-item-meta-label-add-on-text: #c19393
$c-invoice-item-price-text: transparentize(#000000, 0.6)
$c-invoice-item-hover-bg: transparentize(#07967b, 0.7)
$c-invoice-item-active-bg: transparentize(#07967b, 0.7)
$c-invoice-info-bg: #023e18
$c-invoice-table-number-bg: #d06101
$c-invoice-table-number-text: #fdfbed
$c-invoice-total-text: #ffffff

$c-numpad-display-bg: transparent
$c-numpad-display-border: #0c8080
$c-numpad-display-text: #000000
$c-numpad-control-bg: #0c8080
$c-numpad-control-text: #fdfbed
$c-numpad-control-hover-bg: darken(#0c8080, 15%)
$c-numpad-control-clear-bg: transparent
$c-numpad-control-clear-border: #0c8080
$c-numpad-control-clear-text: #0c8080
$c-numpad-control-clear-hover-bg: #0c8080
$c-numpad-control-clear-hover-text: #fdfbed
$c-numpad-transaction-display-bg: transparent
$c-numpad-transaction-display-border: #156e58
$c-numpad-transaction-display-text: #000000
$c-numpad-transaction-control-bg: #156e58
$c-numpad-transaction-control-text: #fdfbed
$c-numpad-transaction-control-hover-bg: darken(#156e58, 10%)
$c-numpad-transaction-control-clear-bg: transparent
$c-numpad-transaction-control-clear-border: #156e58
$c-numpad-transaction-control-clear-text: #156e58
$c-numpad-transaction-control-clear-hover-bg: #156e58
$c-numpad-transaction-control-clear-hover-text: #fdfbed
$c-numpad-sign-in-control-bg: #156e58
$c-numpad-sign-in-control-text: #fdfbed
$c-numpad-sign-in-control-hover-bg: darken(#156e58, 10%)
$c-numpad-sign-in-control-clear-bg: transparent
$c-numpad-sign-in-control-clear-border: #156e58
$c-numpad-sign-in-control-clear-text: #156e58
$c-numpad-sign-in-control-clear-hover-bg: #156e58
$c-numpad-sign-in-control-clear-hover-text: #fdfbed
$c-numpad-new-table-control-bg: #156e58
$c-numpad-new-table-control-text: #fdfbed
$c-numpad-new-table-control-hover-bg: darken(#156e58, 10%)
$c-numpad-new-table-control-clear-bg: transparent
$c-numpad-new-table-control-clear-border: #156e58
$c-numpad-new-table-control-clear-text: #156e58
$c-numpad-new-table-control-clear-hover-bg: #156e58
$c-numpad-new-table-control-clear-hover-text: #fdfbed
$c-numpad-transfer-table-control-bg: #156e58
$c-numpad-transfer-table-control-text: #fdfbed
$c-numpad-transfer-table-control-hover-bg: darken(#156e58, 15%)
$c-numpad-transfer-table-control-clear-bg: transparent
$c-numpad-transfer-table-control-clear-border: #156e58
$c-numpad-transfer-table-control-clear-text: #156e58
$c-numpad-transfer-table-control-clear-hover-bg: #156e58
$c-numpad-transfer-table-control-clear-hover-text: #fdfbed

$c-item-image-bg: #5c5c5c
$c-item-title-text: #050505
$c-item-subtitle-text: #7b7b7b
$c-item-meta-text: transparentize(#0b0b0b, 0.6)
$c-item-meta-label-text: transparentize(#0b0b0b, 0.6)
$c-item-modifiers-bg: transparent
$c-item-modifiers-control-bg: #2e6b98
$c-item-modifiers-control-icon: #fdfbed
$c-item-modifiers-control-hover-bg: darken(#2e6b98, 15%)
$c-item-modifier-bg: #cbcbcb
$c-item-modifier-text: #fdfbed
$c-item-modifier-price-text: transparentize(#fdfbed, 0.6)
$c-item-modifier-icon: transparentize(#fdfbed, 0.6)
$c-item-modifier-price-text: transparentize(#fdfbed, 0.6)
$c-item-modifier-active-bg: #a2a2a2
$c-item-modifier-bottle-active-bg: #8091a0
$c-item-modifier-tower-active-bg: #6a5f79
$c-item-modifier-magnum-active-bg: #685991
$c-item-modifier-stein-active-bg: #a09e80
$c-item-modifier-2-pt-jug-active-bg: #676650
$c-item-modifier-4-pt-jug-active-bg: #4a4838
$c-item-modifier-jug-active-bg: #624b43
$c-item-modifier-pint-active-bg: #80a089
$c-item-modifier-double-active-bg: #80a089
$c-item-modifier-splash-active-bg: #6c4763
$c-item-modifier-scooner-active-bg: #4a4838
$c-item-modifier-half-active-bg: #a08080
$c-item-modifier-single-active-bg: #a08080
$c-item-modifier-small-active-bg: #8a9a9d
$c-item-modifier-medium-active-bg: #707d80
$c-item-modifier-large-active-bg: #515c5f
$c-item-condiments-control-bg: transparent
$c-item-condiments-control-hover-bg: #2e6b98
$c-item-condiments-control-border: #2e6b98
$c-item-condiments-control-icon: #2e6b98
$c-item-condiments-control-hover-icon: #fdfbed
$c-item-condiments-action-bg: #cbcbcb
$c-item-condiments-action-hover-bg: darken(#cbcbcb, 20%)
$c-item-condiments-action-add-on-bg: #b2c7a5
$c-item-condiments-action-add-on-hover-bg: darken(#b2c7a5, 20%)
$c-item-condiments-action-take-off-bg: #c7a5a5
$c-item-condiments-action-take-off-hover-bg: darken(#c7a5a5, 20%)
$c-item-condiments-action-text: #fdfbed
$c-item-condiment-bg: #c1bdc6
$c-item-condiment-hover-bg: darken(#c1bdc6, 20%)
$c-item-condiment-active-bg: #8580a0
$c-item-condiment-take-off-bg: #cbcbcb
$c-item-condiment-add-on-bg: #cbcbcb
$c-item-condiment-text: #fdfbed
$c-item-condiment-price-text: transparentize(#fdfbed, 0.6)
$c-item-condiment-take-off-active-bg: #c7a5a5
$c-item-condiment-add-on-active-bg: #b2c7a5
$c-item-quantity-label-text: #000000
$c-item-action-bg: #156e58
$c-item-action-text: #fdfbed
$c-item-action-hover-bg: darken(#156e58, 10%)

$c-quantity-control-bg: #156e58
$c-quantity-control-text: #ffffff
$c-quantity-control-hover-bg: darken(#156e58, 10%)
$c-quantity-value-bg: transparent
$c-quantity-value-border: #797979
$c-quantity-value-text: #000000

$c-message-title-text: #770c80
$c-message-box-bg: #ffffff
$c-message-box-border: #707070
$c-message-text: #000000
$c-message-preset-bg: #07a09a
$c-message-preset-text: #fdfbed
$c-message-preset-hover-bg: darken(#07a09a, 15%)
$c-message-action-bg: #770c80
$c-message-action-text: #fdfbed
$c-message-action-hover-bg: darken(#770c80, 10%)

$c-switch-control-bg: #535151
$c-switch-control-border: #707070
$c-switch-control-text: #fdfbed
$c-switch-control-hover-bg: darken(#535151, 20%)

$c-keyboard-control-bg: #969595
$c-keyboard-control-text: #fdfbed
$c-keyboard-control-icon: #fdfbed
$c-keyboard-control-hover-bg: darken(#969595, 20%)

$c-void-title-text: #770c80
$c-void-action-bg: #770c80
$c-void-action-text: #fdfbed
$c-void-action-hover-bg: darken(#770c80, 10%)

$c-search-title-text: #770c80
$c-search-products-bg: transparent
$c-search-products-control-bg: #2e6b98
$c-search-products-control-icon: #fdfbed
$c-search-products-control-hover-bg: darken(#2e6b98, 15%)
$c-search-product-bg: #8e8e8e
$c-search-product-text: #fdfbed
$c-search-product-meta-text: transparentize(#fdfbed, 0.5)
$c-search-product-hover-bg: darken(#8e8e8e, 20%)

$c-field-box-bg: transparentize(#fcfcfc, 0.6)
$c-field-box-border: transparentize(#707070, 0.6)
$c-field-box-text: #000000
$c-field-action-bg: #98bd90
$c-field-action-text: #fdfbed
$c-field-action-hover-bg: darken(#98bd90, 20%)
$c-field-action-enter-bg: #109541
$c-field-action-enter-icon: #fdfbed
$c-field-action-enter-hover-bg: darken(#109541, 15%)


$c-transaction-header-action-bg: #6984a3
$c-transaction-header-action-hover-bg: darken(#6984a3, 20%)
$c-transaction-header-action-text: #fdfbed
$c-transaction-action-split-bg: #c17a7a
$c-transaction-action-split-text: #ffffff
$c-transaction-action-split-hover-bg: darken(#c17a7a, 10%)
$c-transaction-action-receipt-bg: #770c80
$c-transaction-action-receipt-text: #ffffff
$c-transaction-action-receipt-hover-bg: darken(#770c80, 10%)
$c-transaction-denomination-bg: #80a080
$c-transaction-denomination-text: #ffffff
$c-transaction-denomination-hover-bg: darken(#80a080, 20%)
$c-transaction-method-cash-bg: #5d775d
$c-transaction-method-cash-text: #ffffff
$c-transaction-method-cash-hover-bg: darken(#5d775d, 15%)
$c-transaction-method-card-bg: #6984a3
$c-transaction-method-card-text: #ffffff
$c-transaction-method-card-hover-bg: darken(#6984a3, 15%)
$c-transaction-method-deposit-bg: #a36993
$c-transaction-method-deposit-text: #ffffff
$c-transaction-method-deposit-hover-bg: darken(#a36993, 15%)

$c-pagination-control-bg: #c9caed
$c-pagination-control-border: #707070
$c-pagination-control-icon: transparentize(#000000, 0.5)
$c-pagination-control-hover-bg: darken(#c9caed, 20%)
$c-pagination-info-bg: transparent
$c-pagination-info-border: #707070
$c-pagination-info-text: transparentize(#000000, 0.5)

$c-data-item-total-border: #707070
$c-data-item-label-total-text: #000000
$c-data-item-label-cash-text: #7f9f7f
$c-data-item-label-card-text: #6984a3
$c-data-item-label-loyalty-text: #6984a3
$c-data-item-label-deposit-text: #6984a3
$c-data-item-label-balance-text: #000000
$c-data-item-label-change-text: #000000
$c-data-item-value-total-text: #000000
$c-data-item-value-cash-text: #7f9f7f
$c-data-item-value-card-text: #6984a3
$c-data-item-value-loyalty-text: #6984a3
$c-data-item-value-deposit-text: #6984a3
$c-data-item-value-balance-bg: #156e58
$c-data-item-value-balance-text: #ffffff
$c-data-item-value-balance-active-bg: #f86018
$c-data-item-value-balance-active-text: #ffffff
$c-data-item-value-change-text: #058b00
$c-data-item-value-change-active-text: #f86018
$c-data-sale-item-label-text: transparentize(#0b0b0b, 0.6)

$c-summary-action-bg: #80a080
$c-summary-action-text: #ffffff
$c-summary-action-hover-bg: darken(#80a080, 20%)

$c-split-action-receipt-bg: #770c80
$c-split-action-receipt-hover-bg: darken(#770c80, 10%)
$c-split-action-receipt-text: #ffffff
$c-split-action-add-bg: #c17a7a
$c-split-action-add-hover-bg: darken(#c17a7a, 20%)
$c-split-action-add-text: #fdfbed
$c-split-action-add-icon: #fdfbed
$c-split-action-done-bg: #80a080
$c-split-action-done-text: #ffffff
$c-split-action-done-hover-bg: darken(#80a080, 20%)
$c-split-control-bg: transparent
$c-split-control-border: #6984a3
$c-split-control-icon: #6984a3
$c-split-control-hover-bg: #6984a3
$c-split-control-hover-icon: #fdfbed

$c-sign-out-title-text: #950d0d
$c-sign-out-message-text: transparentize(#000000, 0.3)
$c-sign-out-action-change-fob-bg: #a36969
$c-sign-out-action-change-fob-hover-bg: darken(#a36969, 20%)
$c-sign-out-action-change-fob-text: #fdfbed
$c-sign-out-action-confirm-bg: #69a37d
$c-sign-out-action-confirm-hover-bg: darken(#69a37d, 20%)
$c-sign-out-action-confirm-text: #fdfbed

$c-welcome-bg: #6984a3
$c-welcome-title-text: #fdfbed
$c-welcome-action-bg: transparent
$c-welcome-action-border: #fdfbed
$c-welcome-action-icon: #fdfbed
$c-welcome-action-hover-bg: transparentize(#000000, 0.7)
$c-welcome-version-text: transparentize(#fdfbed, 0.5)

$c-sign-in-title-text: #0d9541
$c-sign-in-subtitle-text: transparentize(#000000, 0.3)
$c-sign-in-note-text: transparentize(#8b0303, 0.3)
$c-sign-in-name-text: #65a28d
$c-sign-in-error-text: #9f0101
$c-sign-in-label-text: transparentize(#000000, 0.3)
$c-sign-in-servers-bg: transparent
$c-sign-in-servers-control-bg: #2e6b98
$c-sign-in-servers-control-icon: #fdfbed
$c-sign-in-servers-control-hover-bg: darken(#2e6b98, 15%)
$c-sign-in-server-bg: #65a28d
$c-sign-in-server-text: #fdfbed
$c-sign-in-server-meta-text: transparentize(#fdfbed, 0.5)
$c-sign-in-server-hover-bg: darken(#65a28d, 20%)
$c-sign-in-action-bg: #109541
$c-sign-in-action-text: #fdfbed
$c-sign-in-action-hover-bg: darken(#109541, 15%)

$c-tables-title-text: #050505
$c-tables-item-bg: #808ea0
$c-tables-item-hover-bg: darken(#808ea0, 20%)
$c-tables-item-server-text: transparentize(#fdfbed, 0.6)
$c-tables-item-time-text: transparentize(#fdfbed, 0.6)
$c-tables-item-name-text: #fdfbed
$c-tables-item-label-text: transparentize(#fdfbed, 0.6)
$c-tables-item-number-text: transparentize(#fdfbed, 0.6)
$c-tables-action-bg: #969595
$c-tables-action-text: #fdfbed
$c-tables-action-hover-bg: darken(#969595, 20%)
$c-tables-action-transfer-bg: #a36993
$c-tables-action-transfer-text: #fdfbed
$c-tables-action-transfer-hover-bg: darken(#a36993, 20%)
$c-tables-action-new-bg: #69a37d
$c-tables-action-new-text: #fdfbed
$c-tables-action-new-hover-bg: darken(#69a37d, 20%)
$c-tables-action-open-bg: #6d69a3
$c-tables-action-open-text: #fdfbed
$c-tables-action-open-hover-bg: darken(#6d69a3, 20%)
$c-tables-action-close-bg: #d06101
$c-tables-action-close-text: #fdfbed
$c-tables-action-close-hover-bg: darken(#d06101, 20%)

$c-new-table-title-text: #050505
$c-new-table-number-label-text: #050505
$c-new-table-number-display-bg: transparent
$c-new-table-number-display-border: #797979
$c-new-table-number-display-text: #000000
$c-new-table-guests-label-text: #050505
$c-new-table-action-bg: #6d69a3
$c-new-table-action-text: #fdfbed
$c-new-table-action-hover-bg: darken(#6d69a3, 20%)

$c-transfer-table-title-text: #050505
$c-transfer-table-badge-bg: #808ea0
$c-transfer-table-badge-server-text: transparentize(#fdfbed, 0.6)
$c-transfer-table-badge-time-text: transparentize(#fdfbed, 0.6)
$c-transfer-table-badge-name-text: #fdfbed
$c-transfer-table-badge-label-text: transparentize(#fdfbed, 0.6)
$c-transfer-table-badge-number-text: transparentize(#fdfbed, 0.6)
$c-transfer-table-number-label-text: #050505
$c-transfer-table-number-display-bg: transparent
$c-transfer-table-number-display-border: #797979
$c-transfer-table-number-display-text: #000000
$c-transfer-table-guests-label-text: #050505
$c-transfer-table-floorplan-label-text: #ff0000
$c-transfer-table-floorplan-image-bg: #5c5c5c
$c-transfer-table-action-bg: #a36993
$c-transfer-table-action-text: #fdfbed
$c-transfer-table-action-hover-bg: darken(#a36993, 20%)

$c-sales-review-table-border: transparentize(#707070, 0.4)
$c-sales-review-action-bg: #969595
$c-sales-review-action-text: #fdfbed
$c-sales-review-action-hover-bg: darken(#969595, 20%)
$c-sales-review-action-display-sale-bg: #0c8073
$c-sales-review-action-display-sale-text: #fdfbed
$c-sales-review-action-display-sale-hover-bg: darken(#0c8073, 15%)
$c-sales-review-action-main-menu-bg: #8b0b86
$c-sales-review-action-main-menu-text: #fdfbed
$c-sales-review-action-main-menu-hover-bg: darken(#8b0b86, 15%)
$c-sales-review-action-search-bg: #2fa081
$c-sales-review-action-search-text: #fdfbed
$c-sales-review-action-search-hover-bg: darken(#2fa081, 15%)
$c-sales-review-version-text: transparentize(#000000, 0.5)

$c-table-sorting-bg: transparentize(#000000, 0.6)
$c-table-sorting-hover-bg: transparentize(#000000, 0.2)
$c-table-row-bg: transparentize(#cecece, 0.8)
$c-table-row-hover-bg: transparentize(#cecece, 0.4)
$c-table-row-active-bg: transparentize(#cecece, 0.4)
$c-table-row-header-bg: #c3c3c3
$c-table-row-no-sale-bg: #dad9d7
$c-table-row-no-sale-hover-bg: darken(#dad9d7, 10%)
$c-table-row-void-bg: #fef1c0
$c-table-row-void-hover-bg: darken(#fef1c0, 10%)
$c-table-row-void-all-bg: #fdd9c1
$c-table-row-void-all-hover-bg: darken(#fdd9c1, 10%)
$c-table-row-wtl-bg: #fedbf2
$c-table-row-wtl-hover-bg: darken(#fedbf2, 10%)
$c-table-row-refund-bg: #f7c9c4
$c-table-row-refund-hover-bg: darken(#f7c9c4, 10%)
$c-table-row-deposit-bg: #c2e5c1
$c-table-row-deposit-hover-bg: darken(#c2e5c1, 10%)
$c-table-row-discount-bg: #dbcbf0
$c-table-row-discount-hover-bg: darken(#dbcbf0, 10%)
$c-table-cell-border: #fdfbed
$c-table-cell-text: #0b0b0b
$c-table-palceholder-text: #5c5c5c

$c-sale-action-bg: #969595
$c-sale-action-text: #fdfbed
$c-sale-action-hover-bg: darken(#969595, 20%)
$c-sale-action-page-down-bg: #989898
$c-sale-action-page-down-text: #fdfbed
$c-sale-action-page-down-hover-bg: darken(#989898, 20%)
$c-sale-action-print-bg: #c17a7a
$c-sale-action-print-text: #fdfbed
$c-sale-action-print-hover-bg: darken(#c17a7a, 20%)
$c-sale-action-page-up-bg: #989898
$c-sale-action-page-up-text: #fdfbed
$c-sale-action-page-up-hover-bg: darken(#989898, 20%)
$c-sale-action-change-tender-bg: #827ac1
$c-sale-action-change-tender-text: #fdfbed
$c-sale-action-change-tender-hover-bg: darken(#827ac1, 20%)
$c-sale-action-done-bg: #7ac17a
$c-sale-action-done-text: #fdfbed
$c-sale-action-done-hover-bg: darken(#7ac17a, 20%)

$c-servers-overview-title-text: #0d9541
$c-servers-overview-subtitle-text: transparentize(#000000, 0.3)
$c-servers-overview-icon: #2e6b98
$c-servers-overview-icon-scan: #109541
$c-servers-overview-error-text: transparentize(#950d0d, 0.3)
$c-servers-overview-servers-bg: transparent
$c-servers-overview-servers-control-bg: #2e6b98
$c-servers-overview-servers-control-icon: #fdfbed
$c-servers-overview-servers-control-hover-bg: darken(#2e6b98, 15%)
$c-servers-overview-server-bg: #65a28d
$c-servers-overview-server-text: #fdfbed
$c-servers-overview-server-meta-text: #fdfbed
$c-servers-overview-server-hover-bg: darken(#65a28d, 20%)
$c-servers-overview-server-active-bg: #2e6552
$c-servers-overview-action-bg: #a36969
$c-servers-overview-action-text: #fdfbed
$c-servers-overview-action-hover-bg: darken(#a36969, 20%)

$c-pin-entry-devices-title-text: #0d9541
$c-pin-entry-devices-subtitle-text: transparentize(#000000, 0.3)
$c-pin-entry-devices-devices-bg: transparent
$c-pin-entry-devices-devices-control-bg: #2e6b98
$c-pin-entry-devices-devices-control-icon: #fdfbed
$c-pin-entry-devices-devices-control-hover-bg: darken(#2e6b98, 15%)
$c-pin-entry-devices-device-bg: #2e6552
$c-pin-entry-devices-device-text: #fdfbed
$c-pin-entry-devices-server-meta-text: #fdfbed
$c-pin-entry-devices-device-hover-bg: darken(#2e6552, 15%)
$c-pin-entry-devices-device-active-bg: #65a28d

$c-bookings-title-text: #6984a3
$c-bookings-meta-date-bg: #7b7575
$c-bookings-meta-date-text: #fdfbed
$c-bookings-meta-count-bg: #535151
$c-bookings-meta-count-text: #fdfbed
$c-bookings-list-bg: transparent
$c-bookings-list-border: #707070
$c-bookings-item-status-text: #62315b
$c-bookings-item-area-summary-bg: #62315b
$c-bookings-item-area-summary-border: transparentize(#fdfbed, 0.5)
$c-bookings-item-area-details-bg: #906088
$c-bookings-item-name-text: #fdfbed
$c-bookings-item-summary-label-text: #fdfbed
$c-bookings-item-summary-value-text: #fdfbed
$c-bookings-item-details-label-text: #fdfbed
$c-bookings-item-details-value-text: #fdfbed
$c-bookings-item-redeemed-area-summary-bg: #838383
$c-bookings-item-redeemed-area-details-bg: #838383
$c-bookings-action-bg: #80a080
$c-bookings-action-text: #fdfbed
$c-bookings-action-hover-bg: darken(#80a080, 20%)

$c-datepicker-bg: transparent
$c-datepicker-calendar-border: transparentize(#707070, 0.6)
$c-datepicker-month-border: transparentize(#707070, 0.6)
$c-datepicker-month-control-bg: transparent
$c-datepicker-month-control-border: #6984a3
$c-datepicker-month-control-icon: #6984a3
$c-datepicker-month-control-hover-bg: #6984a3
$c-datepicker-month-control-hover-icon: #fdfbed
$c-datepicker-time-border: transparentize(#707070, 0.6)
$c-datepicker-input-hover-bg: #eae8d9
$c-datepicker-input-control-hover-bg: #d2d0c3
$c-datepicker-input-border: transparentize(#707070, 0.6)
$c-datepicker-input-icon: #4a4838
$c-datepicker-weekday-text: #646464
$c-datepicker-day-text: #050505
$c-datepicker-day-border: #050505
$c-datepicker-day-today-bg: #fef1c0
$c-datepicker-day-today-border: #e4d8ac
$c-datepicker-day-hover-bg: #d2dae3
$c-datepicker-day-hover-border: #9fadbd
$c-datepicker-day-hover-text: #050505
$c-datepicker-day-active-bg: #6984a3
$c-datepicker-day-active-border: #6984a3
$c-datepicker-day-active-text: #fdfbed
$c-datepicker-day-in-range-bg: #d2dae3
$c-datepicker-day-in-range-border: #d2dae3
$c-datepicker-day-in-range-text: #050505
$c-datepicker-day-inactive-text: #969595
$c-datepicker-preview-text: #6e6b6b
$c-datepicker-action-bg: #969595
$c-datepicker-action-text: #fdfbed
$c-datepicker-action-hover-bg: darken(#969595, 20%)
$c-datepicker-action-cancel-bg: #c17a7a
$c-datepicker-action-cancel-text: #fdfbed
$c-datepicker-action-cancel-hover-bg: darken(#c17a7a, 20%)
$c-datepicker-action-confirm-bg: #7ac17a
$c-datepicker-action-confirm-text: #fdfbed
$c-datepicker-action-confirm-hover-bg: darken(#7ac17a, 20%)

//////////////////////////////////////// Fonts

$f-sans: 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif
