.waste
  width: 100%
  height: 100%
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
  border-top: 1px solid #ababab
  table
    width: 100%
    margin-top: 5px
    border-collapse: collapse
    border: 1px solid #ababab
    tr
      border: 1px solid #ababab
      height: 50px
    th
      padding: 3px 0 3px 0
      text-align: center
    td
      padding: 3px 0 3px 0
      text-align: center
  
    .col-50
      width: 50px
    
    .col-100
      width: 100px

    button
      width: 100%
      height: 40px
      border-radius: 10px
      font-size: 20px
      font-weight: bold
      background-color: #6699ff
      color: #ffffff
      border: none

    button:hover
      opacity: .8

    .button
      margin-right: 5px

.waste-edit 
  margin-top: 5px
  width: 100%
  height: 100%
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
  border-top: 1px solid #ababab

  .grid
    margin-top: 5px
    display: grid
    gap: 8px
    grid-template-columns: repeat(auto-fit, minmax(162px, 1fr))

  button
    width: 100%
    height: 40px
    border-radius: 9px
    padding: 5px
    font-size: 18px
    font-weight: bold
    background-color: #65a28d
    color: #ffffff
    border: none

  button.default
    background: #c17a7a