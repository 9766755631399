.pos-sign-in__servers-row
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94)
  transition-duration: 0ms
  transform: translate(0px, 0px) translateZ(0px)

.pos-sign-in__servers-scroll
  touch-action: pan-y pinch-zoom

.pos-terminal_closed
  background-color: red

.pos-terminal_blocked
  background-color: orange

.pos-training-mode
  background-color: #770c80

.pos-server-overview-exists
  background-color: #a36969 !important