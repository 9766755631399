@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Invoice
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-invoice"

#{$block}
  display: block
  width: 100%

  // $Invoice MOD: role main
  &_role_main

  // $Invoice MOD: role message-item-select
  &_role_message-item-select

  // $Invoice MOD: role message-item
  &_role_message-item
    height: 100%

  // $Invoice MOD: role void-item-select
  &_role_void-item-select

  // $Invoice MOD: role transaction
  &_role_transaction

  // $Invoice MOD: role split
  &_role_split

  // $Invoice MOD: role sale
  &_role_sale


  // $Invoice panel
  &__panel
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 486px
    margin-bottom: 7px
    border: 1px solid $c-invoice-panel-border
    border-radius: 12px
    background-color: $c-invoice-panel-bg
    overflow: hidden

    #{$block}_role_message-item &
      height: 100%

    +media-screen-1280x800
      height: 385px
      border-radius: 9px

    +media-screen-1024x768
      height: 365px
      margin-bottom: 6px
      border-radius: 9px

      #{$block}_role_message-item &
        height: 100%

    +media-screen-1024x600
      height: 265px
      margin-bottom: 6px
      border-radius: 7px

      #{$block}_role_message-item &
        height: 100%


  // $Invoice panel header
  &__panel-header
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 100%
    border-bottom: 1px solid $c-invoice-panel-border
    background-color: $c-invoice-panel-header-bg


  // $Invoice panel title
  &__panel-title
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 35px
    line-height: 43px
    font-weight: 700
    color: $c-invoice-panel-title-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 26px
      line-height: 32px

    +media-screen-1024x768
      font-size: 26px
      line-height: 32px

    +media-screen-1024x600
      font-size: 21px
      line-height: 25px


  // $Invoice panel content
  &__panel-content
    flex-grow: 1
    flex-shrink: 1
    position: relative
    display: block
    width: 100%
    overflow: hidden


  // $Invoice panel footer
  &__panel-footer
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 100%
    background-color: $c-invoice-panel-footer-bg


  // $Invoice panel deposit
  &__panel-deposit
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%
    background-color: $c-invoice-panel-deposit-bg


  // $Invoice panel deposit info
  &__panel-deposit-info
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: 65%
    padding: 8px 0 8px 12px

    +media-screen-1280x800
      padding: 7px 0 7px 12px

    +media-screen-1024x768
      padding: 6px 0 6px 8px

    +media-screen-1024x600
      padding: 4px 0 4px 8px


  // $Invoice panel deposit label
  &__panel-deposit-label
    display: block
    width: 100%
    margin-bottom: 2px
    font-family: $f-sans
    font-size: 14px
    line-height: 14px
    font-weight: 700
    color: $c-invoice-panel-deposit-text
    text-transform: uppercase
    text-align: center
    white-space: nowrap

    +media-screen-1280x800
      font-size: 13px
      line-height: 13px

    +media-screen-1024x768
      font-size: 12px
      line-height: 12px

    +media-screen-1024x600
      font-size: 12px
      line-height: 12px


  // $Invoice panel deposit name
  &__panel-deposit-name
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 14px
    line-height: 14px
    font-weight: 500
    color: $c-invoice-panel-deposit-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 13px
      line-height: 13px

    +media-screen-1024x768
      font-size: 12px
      line-height: 12px

    +media-screen-1024x600
      font-size: 12px
      line-height: 12px


  // $Invoice panel deposit value
  &__panel-deposit-value
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 35%
    font-family: $f-sans
    font-size: 28px
    line-height: 28px
    font-weight: 700
    color: $c-invoice-panel-deposit-text
    text-transform: uppercase
    text-align: center
    padding: 8px 12px 8px 0

    +media-screen-1280x800
      padding: 6px 8px 6px 0
      font-size: 24px
      line-height: 24px

    +media-screen-1024x768
      padding: 6px 8px 6px 0
      font-size: 21px
      line-height: 21px

    +media-screen-1024x600
      padding: 4px 8px 4px 0
      font-size: 17px
      line-height: 17px


  // $Invoice panel function
  &__panel-function
    display: block
    width: 100%
    padding-right: 35%
    font-family: $f-sans
    font-size: 35px
    line-height: 43px
    font-weight: 700
    color: $c-invoice-panel-function-text
    text-transform: uppercase
    text-align: right

    +media-screen-1280x800
      font-size: 26px
      line-height: 32px

    +media-screen-1024x768
      padding-right: 39%
      font-size: 26px
      line-height: 32px

    +media-screen-1024x600
      padding-right: 39%
      font-size: 21px
      line-height: 25px


  // $Invoice list
  &__list
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch


  // $Invoice list labels
  &__list-labels
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 25px
    padding: 0 5px

    +media-screen-1024x768
      #{$block}_role_message-item &
        height: 20px

    +media-screen-1024x600
      height: 20px


  // $Invoice list label
  &__list-label
    display: block
    flex-grow: 1
    flex-shrink: 1
    font-family: $f-sans
    font-size: 14px
    line-height: 25px
    font-weight: 500
    text-decoration: underline
    color: $c-invoice-list-label-text
    text-align: center

    +media-screen-1280x800
      font-size: 13px

    +media-screen-1024x768
      font-size: 13px

    +media-screen-1024x600
      font-size: 13px

    &:nth-child(1)
      width: 58%
      padding-left: 15px
      text-align: left

      #{$block}_role_message-item &
        width: 85%
        padding-left: 0

      +media-screen-1024x768
        width: 51%

        #{$block}_role_message-item &
          width: 85%

      +media-screen-1024x600
        width: 51%

        #{$block}_role_message-item &
          width: 85%

    &:nth-child(2)
      width: 10%

      +media-screen-1024x768
        width: 11%

      +media-screen-1024x600
        width: 11%

    &:nth-child(3)
      width: 16%

      +media-screen-1024x768
        width: 19%

      +media-screen-1024x600
        width: 19%

    &:nth-child(4)
      width: 16%

      #{$block}_role_message-item &
        width: 15%

      +media-screen-1024x768
        width: 19%

        #{$block}_role_message-item &
          width: 15%

      +media-screen-1024x600
        width: 19%

        #{$block}_role_message-item &
          width: 15%


  // $Invoice list scroll
  &__list-scroll
    flex-grow: 1
    flex-shrink: 1
    position: relative
    display: block
    width: 100%
    height: calc(100% - 25px)
    overflow: hidden

    +media-screen-1024x600
      height: calc(100% - 20px)


  // $Invoice items
  &__items
    display: block
    width: 100%


  // $Invoice item
  &__item
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: stretch
    width: 100%
    padding: 2px 5px
    cursor: pointer
    user-select: none
    background-color: $c-invoice-item-bg
    transition: all .2s

    &:hover
      background-color: $c-invoice-item-hover-bg

      #{$block}_role_message-item &,
      #{$block}_role_transaction &
        cursor: default
        background-color: $c-invoice-item-bg

    &:not(:last-child)
      margin-bottom: 10px

    // $Invoice item STATE: active
    &.is-active
      background-color: $c-invoice-item-active-bg


  // $Invoice item info
  &__item-info
    width: 58%

    #{$block}_role_message-item &
      width: 85%

    +media-screen-1024x768
      width: 51%

      #{$block}_role_message-item &
        width: 85%

    +media-screen-1024x600
      width: 51%

      #{$block}_role_message-item &
        width: 85%


  // $Invoice item qty
  &__item-qty
    width: 10%
    text-align: center

    #{$block}_role_message-item &
      width: 15%

    +media-screen-1024x768
      width: 11%

      #{$block}_role_message-item &
        width: 15%

    +media-screen-1024x600
      width: 11%

      #{$block}_role_message-item &
        width: 15%


  // $Invoice item price
  &__item-price
    width: 16%

    +media-screen-1024x768
      width: 19%

    +media-screen-1024x600
      width: 19%

  // $Invoice item total
  &__item-total
    width: 16%

    +media-screen-1024x768
      width: 19%

    +media-screen-1024x600
      width: 19%


  // $Invoice item text
  &__item-text
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 14px
    line-height: 18px
    font-weight: 500
    color: $c-invoice-item-text

    +media-screen-1280x800
      font-size: 13px
      line-height: 16px

    +media-screen-1024x768
      font-size: 13px
      line-height: 16px

    +media-screen-1024x600
      font-size: 13px
      line-height: 16px

    // $Invoice item text CONTEXT: $Invoice item price
    #{$block}__item-price &
      color: $c-invoice-item-price-text
      white-space: nowrap

    // $Invoice item text CONTEXT: $Invoice item total
    #{$block}__item-total &
      white-space: nowrap


  // $Invoice item meta
  &__item-meta
    display: block
    width: 100%
    padding-left: 5px

    &:not(:last-child)
      margin-bottom: 5px


  // $Invoice item meta group
  &__item-meta-group
    display: block
    width: 100%

    &:not(:last-child)
      margin-bottom: 5px


  // $Invoice item meta label
  &__item-meta-label
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 12px
    line-height: 15px
    font-weight: 700
    color: $c-invoice-item-meta-label-text

    // $Invoice item meta label MOD: role take off
    &_role_take-off
      color: $c-invoice-item-meta-label-take-off-text

    // $Invoice item meta label MOD: role add on
    &_role_add-on
      color: $c-invoice-item-meta-label-add-on-text


  // $Invoice item meta text
  &__item-meta-text
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 12px
    line-height: 15px
    font-weight: 500
    color: $c-invoice-item-meta-text


  // $Invoice info
  &__info
    position: relative
    z-index: 1
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 43px
    border-radius: 12px
    background-color: $c-invoice-info-bg
    overflow: hidden

    +media-screen-1280x800
      height: 32px
      border-radius: 9px

    +media-screen-1024x768
      height: 32px
      border-radius: 9px

    +media-screen-1024x600
      height: 25px
      border-radius: 7px


  // $Invoice table number
  &__table-number
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 150px
    height: 43px
    font-family: $f-sans
    font-size: 30px
    line-height: 43px
    font-weight: 700
    color: $c-invoice-table-number-text
    text-align: center
    border-radius: 12px
    background-color: $c-invoice-table-number-bg
    overflow: hidden

    +media-screen-1280x800
      width: 120px
      height: 32px
      font-size: 22px
      line-height: 32px
      border-radius: 9px

    +media-screen-1024x768
      width: 120px
      height: 32px
      font-size: 22px
      line-height: 32px
      border-radius: 9px

    +media-screen-1024x600
      width: 120px
      height: 25px
      font-size: 18px
      line-height: 25px
      border-radius: 7px


  // $Invoice total
  &__total
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    height: 43px
    padding: 0 12px 0 4px
    font-family: $f-sans
    font-size: 35px
    line-height: 43px
    font-weight: 400
    color: $c-invoice-total-text
    white-space: nowrap

    +media-screen-1280x800
      height: 32px
      font-size: 26px
      line-height: 32px

    +media-screen-1024x768
      height: 32px
      padding: 0 12px 0 6px
      font-size: 26px
      line-height: 32px

    +media-screen-1024x600
      height: 25px
      padding: 0 8px 0 4px
      font-size: 21px
      line-height: 25px

    &:only-child
      padding: 0 20px


  // $Invoice total label
  &__total-label
    display: block
    font-size: 21px
    line-height: 43px
    font-weight: 500
    text-transform: uppercase
    text-align: left

    +media-screen-1280x800
      font-size: 15px
      line-height: 32px

    +media-screen-1024x768
      font-size: 15px
      line-height: 32px

    +media-screen-1024x600
      font-size: 13px
      line-height: 25px


  // $Invoice total value
  &__total-value
    display: block
    font-size: 35px
    line-height: 43px
    font-weight: 700
    text-align: right

    +media-screen-1280x800
      font-size: 26px
      line-height: 32px

    +media-screen-1024x768
      font-size: 26px
      line-height: 32px

    +media-screen-1024x600
      font-size: 21px
      line-height: 25px
