.pos-item__title-text
  white-space: nowrap
  display: inline-block
  font-size: 60px

.pos-item__modifiers-row
  transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1)
  transition-duration: 0ms
  transform: translate(0px, 0px) translateZ(0px)

.pos-item__modifiers-scroll
  touch-action: pan-y pinch-zoom

.pos-item__image
  visibility: hidden

.pos-item__add-button
  flex-grow: 0
  flex-shrink: 0
  display: inline
  width: 265px
  height: 70px
  font-family: 'Montserrat', sans-serif
  font-size: 50px
  line-height: 70px
  font-weight: 500
  color: #ffffff
  text-align: center
  border: none
  box-shadow: none
  border-radius: 12px
  background-color: #156e58
  cursor: pointer
  transition: all .2s

  &:disabled,
  &[disabled],
  &.is-disabled
    cursor: default
    opacity: .4

.pos-item-is-order-device-fired
  background-color: #7f9f7f !important
