@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Menu
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-menu"

#{$block}
  display: block
  width: 100%

  // $Menu MOD: role main
  &_role-main


  // $Menu categories
  &__categories
    display: block
    width: 100%
    margin-bottom: 7px

    +media-screen-1024x768
      margin-bottom: 6px

    +media-screen-1024x600
      margin-bottom: 6px


  // $Menu categories row
  &__categories-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    width: 100%

    &:not(:last-child)
      margin-bottom: 7px

      +media-screen-1024x768
        margin-bottom: 6px

      +media-screen-1024x600
        margin-bottom: 6px


  // $Menu category
  &__category
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: calc(12.5% - 7px)
    height: 77px
    margin-right: 7px
    border-radius: 12px 12px 39px 12px
    background-color: $c-menu-category-bg
    cursor: pointer
    user-select: none
    text-decoration: none
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      border-radius: 9px 9px 29px 9px

    +media-screen-1024x768
      width: calc(12.5% - 6px)
      height: 57px
      margin-right: 6px
      border-radius: 9px 9px 29px 9px

    +media-screen-1024x600
      width: calc(12.5% - 6px)
      height: 42px
      margin-right: 6px
      border-radius: 7px 7px 23px 7px

    &:hover
      background-color: $c-menu-category-hover-bg

    &:focus
      outline: none

    // $Menu category STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Menu category STATE: active
    &.is-active
      background-color: $c-menu-category-active-bg

      &:hover
        opacity: 1

      &:after
        content: ''
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        z-index: -1
        display: block
        border-radius: 12px 12px 0 12px
        background-color: $c-menu-category-active-corner

        +media-screen-1280x800
          border-radius: 9px 9px 0 9px

        +media-screen-1024x768
          border-radius: 9px 9px 0 9px

        +media-screen-1024x600
          border-radius: 7px 7px 0 7px

    // $Menu category MOD: role home
    &_role_home
      background-color: $c-menu-category-home-bg

    // $Menu category MOD: role home STATE: active
    &_role_home.is-active
      background-color: $c-menu-category-home-active-bg

      &:after
        background-color: $c-menu-category-home-active-corner


  // $Menu category text
  &__category-text
    display: block
    font-family: $f-sans
    font-size: 17px
    line-height: 20px
    font-weight: 700
    color: $c-menu-category-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 13px
      line-height: 16px

    +media-screen-1024x768
      font-size: 12px
      line-height: 15px

    +media-screen-1024x600
      font-size: 12px
      line-height: 15px


  // $Menu category icon
  &__category-icon
    display: block
    margin: 0 auto
    font-size: 0
    line-height: 0
    color: transparent

    // $Menu category icon MOD: role home
    &_role_home
      &:before
        content: '\e903'
        display: block
        font-family: icons
        font-size: 22px
        line-height: 22px
        color: $c-menu-category-icon

        +media-screen-1280x800
          font-size: 17px
          line-height: 17px

        +media-screen-1024x768
          font-size: 16px
          line-height: 16px

        +media-screen-1024x600
          font-size: 14px
          line-height: 14px


  // $Menu subcategories
  &__subcategories
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    width: calc(100% - 7px)
    margin: 0 7px 7px 0
    border-radius: 12px
    background-color: $c-menu-subcategories-bg

    +media-screen-1280x800
      border-radius: 9px

    +media-screen-1024x768
      width: calc(100% - 6px)
      margin: 0 6px 6px 0
      border-radius: 9px

    +media-screen-1024x600
      width: calc(100% - 6px)
      margin: 0 6px 6px 0
      border-radius: 7px


  // $Menu subcategories control
  &__subcategories-control
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 48px
    height: 77px
    margin: 0
    padding: 0
    font-size: 0
    line-height: 0
    color: transparent
    text-align: center
    border: none
    box-shadow: none
    border-radius: 12px
    background-color: $c-menu-subcategories-control-bg
    cursor: pointer
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      border-radius: 9px

    +media-screen-1024x768
      width: 38px
      height: 57px
      border-radius: 9px

    +media-screen-1024x600
      width: 38px
      height: 42px
      border-radius: 7px

    &:before
      content: ''
      display: inline-block
      font-family: icons
      font-size: 24px
      line-height: 24px
      color: $c-menu-subcategories-control-icon

      +media-screen-1280x800
        font-size: 19px
        line-height: 19px

      +media-screen-1024x768
        font-size: 18px
        line-height: 18px

      +media-screen-1024x600
        font-size: 15px
        line-height: 15px

    &:hover
      background-color: $c-menu-subcategories-control-hover-bg

    &:focus
      outline: none

    // $Menu subcategories control STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Menu subcategories control MOD: role previous
    &_role_previous
      &:before
        content: '\e901'

    // $Menu subcategories control MOD: role next
    &_role_next
      &:before
        content: '\e902'


  // $Menu subcategories list
  &__subcategories-list
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: calc(100% - 96px)
    padding: 0 7px
    text-align: center

    +media-screen-1024x768
      width: calc(100% - 76px)

    +media-screen-1024x600
      width: calc(100% - 76px)


  // $Menu subcategories scroll
  &__subcategories-scroll
    position: relative
    display: block
    width: 100%
    overflow: hidden
    text-align: center


  // $Menu subcategories row
  &__subcategories-row
    display: inline-flex
    flex-direction: row
    justify-content: flex-start
    align-items: center


  // $Menu subcategory
  &__subcategory
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 98px
    height: 77px
    border-radius: 12px 12px 39px 12px
    background-color: $c-menu-subcategory-bg
    cursor: pointer
    user-select: none
    text-decoration: none
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      border-radius: 9px 9px 29px 9px

    +media-screen-1024x768
      width: 78px
      height: 57px
      border-radius: 9px 9px 29px 9px

    +media-screen-1024x600
      width: 78px
      height: 42px
      border-radius: 7px 7px 23px 7px

    +media-screen-960x600
      width: 68px
      height: 42px
      border-radius: 7px 7px 23px 7px

    &:hover
      background-color: $c-menu-subcategory-hover-bg !important

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 6px

      +media-screen-1024x768
        margin-right: 5px

      +media-screen-1024x600
        margin-right: 5px

    // $Menu subcategory STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Menu subcategory STATE: active
    &.is-active

      &:hover
        opacity: 1

      &:after
        content: ''
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        z-index: -1
        display: block
        border-radius: 12px 12px 0 12px
        background-color: $c-menu-subcategory-active-corner

        +media-screen-1280x800
          border-radius: 9px 9px 0 9px

        +media-screen-1024x768
          border-radius: 9px 9px 0 9px

        +media-screen-1024x600
          border-radius: 7px 7px 0 7px


  // $Menu subcategory text
  &__subcategory-text
    display: block
    font-family: $f-sans
    font-size: 14px
    line-height: 18px
    font-weight: 700
    color: $c-menu-category-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 12px
      line-height: 15px

    +media-screen-1024x768
      font-size: 11px
      line-height: 14px

    +media-screen-1024x600
      font-size: 11px
      line-height: 14px


  // $Menu items
  &__items
    display: block
    width: 100%


  // $Menu items row
  &__items-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    width: 100%

    &:not(:last-child)
      margin-bottom: 7px

      +media-screen-1024x768
        margin-bottom: 6px

      +media-screen-1024x600
        margin-bottom: 6px


  // $Menu items control
  &__items-control
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: calc(25% - 7px)
    height: 77px
    margin: 0
    padding: 0
    margin-right: 7px
    font-size: 0
    line-height: 0
    color: transparent
    text-align: center
    box-shadow: none
    border: 5px solid $c-menu-items-control-border
    border-radius: 12px
    background-color: $c-menu-items-control-bg
    cursor: pointer
    user-select: none
    overflow: hidden
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      border-width: 4px
      border-radius: 9px

    +media-screen-1024x768
      height: 57px
      border-width: 4px
      border-radius: 9px

    +media-screen-1024x600
      height: 42px
      border-width: 4px
      border-radius: 7px

    &:before
      content: ''
      display: inline-block
      font-family: icons
      font-size: 52px
      line-height: 52px
      color: $c-menu-items-control-icon

      +media-screen-1280x800
        font-size: 36px
        line-height: 36px

      +media-screen-1024x768
        font-size: 36px
        line-height: 36px

      +media-screen-1024x600
        font-size: 26px
        line-height: 26px

    &:hover
      background-color: $c-menu-items-control-hover-bg !important

      &:before
        color: $c-menu-items-control-hover-icon

    &:focus
      outline: none

    // $Menu items control STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Menu items control MOD: role previous
    &_role_previous
      &:before
        content: '\e901'

    // $Menu items control MOD: role next
    &_role_next
      margin-left: auto

      &:before
        content: '\e902'


  // $Menu item
  &__item
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: calc(25% - 7px)
    height: 77px
    margin-right: 7px
    border-radius: 12px
    background-color: $c-menu-subcategory-bg
    cursor: pointer
    user-select: none
    text-decoration: none
    overflow: hidden
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      border-radius: 9px

    +media-screen-1024x768
      width: calc(25% - 6px)
      height: 57px
      margin-right: 6px
      border-radius: 9px

    +media-screen-1024x600
      width: calc(25% - 6px)
      height: 42px
      margin-right: 6px
      border-radius: 7px

    &:hover
      background-color: $c-menu-subcategory-hover-bg !important

    &:focus
      outline: none

    // $Menu item STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none


  // $Menu item meta
  &__item-meta
    display: block
    font-family: $f-sans
    font-size: 14px
    line-height: 18px
    font-weight: 300
    color: $c-menu-item-meta-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 11px
      line-height: 13px

    +media-screen-1024x768
      font-size: 11px
      line-height: 13px

    +media-screen-1024x600
      font-size: 9px
      line-height: 11px


  // $Menu item text
  &__item-text
    display: block
    padding: 4px 0
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-menu-item-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      padding: 3px 0
      font-size: 15px
      line-height: 17px

    +media-screen-1024x768
      padding: 3px 0
      font-size: 15px
      line-height: 17px

    +media-screen-1024x600
      padding: 1px 0
      font-size: 13px
      line-height: 15px
