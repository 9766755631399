@import '../helpers/settings'
@import '../helpers/mixins'

////////////////////////////////////////
// $Item
////////////////////////////////////////

//////////////////////////////////////// Block variables


//////////////////////////////////////// Block mixins and functions


//////////////////////////////////////// Block styles
$block: ".pos-item"

#{$block}
  display: block
  width: 100%
  padding: 12px 16px

  +media-screen-1280x800
    padding: 10px 16px

  +media-screen-1024x768
    padding: 10px 12px

  +media-screen-1024x600
    padding: 8px 12px


  // $Item header
  &__header
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    width: 100%
    margin-bottom: 26px

    +media-screen-1280x800
      margin-bottom: 18px

    +media-screen-1024x768
      margin-bottom: 18px

    +media-screen-1024x600
      margin-bottom: 14px


  // $Item image
  &__image
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 110px
    height: 110px
    border-radius: 15px
    background-color: $c-item-image-bg
    overflow: hidden

    +media-screen-1280x800
      width: 90px
      height: 90px
      border-radius: 12px

    +media-screen-1024x768
      width: 80px
      height: 80px
      border-radius: 12px

    +media-screen-1024x600
      width: 70px
      height: 70px
      border-radius: 9px

    & img
      display: block
      width: 100%
      height: 100%


  // $Item info
  &__info
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: calc(100% - 250px)
    padding: 0 20px 0 40px

    +media-screen-1280x800
      width: calc(100% - 210px)

    +media-screen-1024x768
      width: calc(100% - 190px)

    +media-screen-1024x600
      width: calc(100% - 180px)


  // $Item title
  &__title
    display: block
    width: 100%
    text-align: center
    margin-bottom: 5px


  // $Item title text
  &__title-text
    display: inline-block
    font-family: $f-sans
    font-size: 60px
    line-height: 1
    font-weight: 700
    color: $c-item-title-text
    text-transform: uppercase

    +media-screen-1280x800
      font-size: 45px

    +media-screen-1024x768
      font-size: 45px

    +media-screen-1024x600
      font-size: 35px


  // $Item subtitle
  &__subtitle
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 40px
    line-height: 1
    font-weight: 700
    color: $c-item-subtitle-text
    text-align: center

    +media-screen-1280x800
      font-size: 30px

    +media-screen-1024x768
      font-size: 30px

    +media-screen-1024x600
      font-size: 25px


  // $Item details
  &__details
    flex-grow: 0
    flex-shrink: 0
    display: block
    width: 140px

    +media-screen-1280x800
      width: 120px

    +media-screen-1024x768
      width: 110px

    +media-screen-1024x600
      width: 110px

  // $Item meta
  &__meta
    display: block
    width: 100%


  // $Item meta label
  &__meta-label
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 17px
    line-height: 20px
    font-weight: 500
    color: $c-item-meta-label-text
    text-align: center

    +media-screen-1280x800
      font-size: 15px
      line-height: 18px

    +media-screen-1024x768
      font-size: 15px
      line-height: 18px

    +media-screen-1024x600
      font-size: 13px
      line-height: 16px


  // $Item meta text
  &__meta-text
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 17px
    line-height: 20px
    font-weight: 500
    color: $c-item-meta-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      font-size: 15px
      line-height: 18px

    +media-screen-1024x768
      font-size: 15px
      line-height: 18px

    +media-screen-1024x600
      font-size: 13px
      line-height: 16px


  // $Item options
  &__options
    display: block
    width: 100%


  // $Item modifiers
  &__modifiers
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    width: 100%
    margin-bottom: 26px
    border-radius: 12px
    background-color: $c-item-modifiers-bg

    +media-screen-1280x800
      margin-bottom: 18px
      border-radius: 9px

    +media-screen-1024x768
      margin-bottom: 18px
      border-radius: 9px

    +media-screen-1024x600
      margin-bottom: 14px
      border-radius: 7px


  // $Item modifiers control
  &__modifiers-control
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: 48px
    height: 98px
    margin: 0
    padding: 0
    font-size: 0
    line-height: 0
    color: transparent
    text-align: center
    border: none
    box-shadow: none
    border-radius: 12px
    background-color: $c-item-modifiers-control-bg
    cursor: pointer
    transition: all .2s

    +media-screen-1280x800
      height: 72px
      border-radius: 9px

    +media-screen-1024x768
      width: 38px
      height: 72px
      border-radius: 9px

    +media-screen-1024x600
      width: 38px
      height: 50px
      border-radius: 7px

    &:before
      content: ''
      display: inline-block
      font-family: icons
      font-size: 24px
      line-height: 24px
      color: $c-item-modifiers-control-icon

      +media-screen-1280x800
        font-size: 19px
        line-height: 19px

      +media-screen-1024x768
        font-size: 18px
        line-height: 18px

      +media-screen-1024x600
        font-size: 15px
        line-height: 15px

    &:hover
      background-color: $c-item-modifiers-control-hover-bg

    &:focus
      outline: none

    // $Item modifiers control STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Item modifiers control MOD: role previous
    &_role_previous
      &:before
        content: '\e901'

    // $Item modifiers control MOD: role next
    &_role_next
      &:before
        content: '\e902'


  // $Item modifiers list
  &__modifiers-list
    flex-grow: 1
    flex-shrink: 1
    display: block
    width: calc(100% - 96px)
    padding: 0 12px
    text-align: center

    +media-screen-1024x768
      width: calc(100% - 76px)
      padding: 0 9px

    +media-screen-1024x600
      width: calc(100% - 76px)
      padding: 0 9px


  // $Item modifiers scroll
  &__modifiers-scroll
    position: relative
    display: block
    width: 100%
    overflow: hidden
    text-align: center


  // $Item modifiers row
  &__modifiers-row
    display: inline-flex
    flex-direction: row
    justify-content: flex-start
    align-items: center


  // $Item modifier
  &__modifier
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    width: 145px
    height: 98px
    padding: 18px 12px 8px
    border-radius: 12px
    background-color: $c-item-modifier-bg
    cursor: pointer
    user-select: none
    text-decoration: none
    transition: all .2s

    +media-screen-1280x800
      height: 72px
      padding: 8px 12px
      border-radius: 9px

    +media-screen-1024x768
      width: 119px
      height: 72px
      padding: 8px
      border-radius: 9px

    +media-screen-1024x600
      width: 119px
      height: 50px
      padding: 4px 8px
      border-radius: 7px

    &:hover
      opacity: .8

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 14px

      +media-screen-1024x768
        margin-right: 8px

      +media-screen-1024x600
        margin-right: 8px

    // $Item modifier STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Item modifier STATE: active
    &.is-active
      background-color: $c-item-modifier-active-bg

      &:hover
        opacity: 1

    &_role_bottle.is-active
      background-color: $c-item-modifier-bottle-active-bg

    &_role_tower.is-active
      background-color: $c-item-modifier-tower-active-bg

    &_role_magnum.is-active
      background-color: $c-item-modifier-magnum-active-bg

    &_role_stein.is-active
      background-color: $c-item-modifier-stein-active-bg

    &_role_2-pt-jug.is-active
      background-color: $c-item-modifier-2-pt-jug-active-bg

    &_role_4-pt-jug.is-active
      background-color: $c-item-modifier-4-pt-jug-active-bg

    &_role_jug.is-active
      background-color: $c-item-modifier-jug-active-bg

    &_role_pint.is-active
      background-color: $c-item-modifier-pint-active-bg

    &_role_double.is-active
      background-color: $c-item-modifier-double-active-bg

    &_role_splash.is-active
      background-color: $c-item-modifier-splash-active-bg

    &_role_scooner.is-active
      background-color: $c-item-modifier-scooner-active-bg

    &_role_half.is-active
      background-color: $c-item-modifier-half-active-bg

    &_role_single.is-active
      background-color: $c-item-modifier-single-active-bg

    &_role_small.is-active
      background-color: $c-item-modifier-small-active-bg

    &_role_medium.is-active
      background-color: $c-item-modifier-medium-active-bg

    &_role_large.is-active
      background-color: $c-item-modifier-large-active-bg


  // $Item modifier text
  &__modifier-text
    display: block
    font-family: $f-sans
    font-size: 25px
    line-height: 1
    font-weight: 700
    color: $c-item-modifier-text
    text-transform: uppercase
    text-align: center
    white-space: nowrap

    +media-screen-1280x800
      font-size: 22px

    +media-screen-1024x768
      font-size: 20px

    +media-screen-1024x600
      font-size: 17px


  // $Item modifier group
  &__modifier-group
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%


  // $Item modifier price
  &__modifier-price
    flex-grow: 0
    flex-shrink: 0
    display: block
    font-family: $f-sans
    font-size: 17px
    line-height: 1
    font-weight: 700
    color: $c-item-modifier-price-text
    text-align: center

    +media-screen-1280x800
      font-size: 16px

    +media-screen-1024x768
      font-size: 15px

    +media-screen-1024x600
      font-size: 14px


  // $Item modifier price
  &__modifier-icon
    flex-grow: 0
    flex-shrink: 0
    display: block

    &:before
      content: ''
      display: inline-block
      font-family: icons
      line-height: 1
      color: $c-item-modifier-icon

    #{$block}__modifier_role_bottle &
      &:before
        content: '\e909'
        font-size: 31px

        +media-screen-1280x800
          font-size: 25px

        +media-screen-1024x768
          font-size: 25px

        +media-screen-1024x600
          font-size: 20px

    #{$block}__modifier_role_tower &
      &:before
        content: '\e916'
        font-size: 35px

        +media-screen-1280x800
          font-size: 27px

        +media-screen-1024x768
          font-size: 27px

        +media-screen-1024x600
          font-size: 21px

    #{$block}__modifier_role_magnum &
      &:before
        content: '\e90e'
        font-size: 39px

        +media-screen-1280x800
          font-size: 27px

        +media-screen-1024x768
          font-size: 27px

        +media-screen-1024x600
          font-size: 21px

    #{$block}__modifier_role_stein &
      &:before
        content: '\e915'
        font-size: 28px

        +media-screen-1280x800
          font-size: 22px

        +media-screen-1024x768
          font-size: 22px

        +media-screen-1024x600
          font-size: 17px

    #{$block}__modifier_role_2-pt-jug &
      &:before
        content: '\e907'
        font-size: 30px

        +media-screen-1280x800
          font-size: 24px

        +media-screen-1024x768
          font-size: 24px

        +media-screen-1024x600
          font-size: 19px

    #{$block}__modifier_role_4-pt-jug &
      &:before
        content: '\e908'
        font-size: 30px

        +media-screen-1280x800
          font-size: 24px

        +media-screen-1024x768
          font-size: 24px

        +media-screen-1024x600
          font-size: 19px

    #{$block}__modifier_role_jug &
      &:before
        content: '\e90c'
        font-size: 30px

        +media-screen-1280x800
          font-size: 24px

        +media-screen-1024x768
          font-size: 24px

        +media-screen-1024x600
          font-size: 19px

    #{$block}__modifier_role_pint &
      &:before
        content: '\e910'
        font-size: 33px

        +media-screen-1280x800
          font-size: 26px

        +media-screen-1024x768
          font-size: 26px

        +media-screen-1024x600
          font-size: 20px

    #{$block}__modifier_role_double &
      &:before
        content: '\e90a'
        font-size: 33px

        +media-screen-1280x800
          font-size: 26px

        +media-screen-1024x768
          font-size: 26px

        +media-screen-1024x600
          font-size: 20px

    #{$block}__modifier_role_splash &
      &:before
        content: '\e914'
        font-size: 21px

        +media-screen-1280x800
          font-size: 20px

        +media-screen-1024x768
          font-size: 20px

        +media-screen-1024x600
          font-size: 16px

    #{$block}__modifier_role_scooner &
      &:before
        content: '\e911'
        font-size: 33px

        +media-screen-1280x800
          font-size: 26px

        +media-screen-1024x768
          font-size: 26px

        +media-screen-1024x600
          font-size: 20px

    #{$block}__modifier_role_half &
      &:before
        content: '\e90b'
        font-size: 21px

        +media-screen-1280x800
          font-size: 19px

        +media-screen-1024x768
          font-size: 19px

        +media-screen-1024x600
          font-size: 16px

    #{$block}__modifier_role_single &
      &:before
        content: '\e912'
        font-size: 21px

        +media-screen-1280x800
          font-size: 19px

        +media-screen-1024x768
          font-size: 19px

        +media-screen-1024x600
          font-size: 16px

    #{$block}__modifier_role_small &
      &:before
        content: '\e913'
        font-size: 20px

        +media-screen-1280x800
          font-size: 18px

        +media-screen-1024x768
          font-size: 18px

        +media-screen-1024x600
          font-size: 15px

    #{$block}__modifier_role_medium &
      &:before
        content: '\e90f'
        font-size: 20px

        +media-screen-1280x800
          font-size: 18px

        +media-screen-1024x768
          font-size: 18px

        +media-screen-1024x600
          font-size: 15px

    #{$block}__modifier_role_large &
      &:before
        content: '\e90d'
        font-size: 20px

        +media-screen-1280x800
          font-size: 18px

        +media-screen-1024x768
          font-size: 18px

        +media-screen-1024x600
          font-size: 15px


  // $Item condiments
  &__condiments
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 314px
    margin-bottom: 18px
    padding-left: 1px

    +media-screen-1280x800
      height: 268px
      margin-bottom: 14px

    +media-screen-1024x768
      height: 232px
      margin-bottom: 14px
      padding-left: 0

    +media-screen-1024x600
      height: 192px
      margin-bottom: 12px
      padding-left: 0


  // $Item condiments row
  &__condiments-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    width: 100%

    &:not(:last-child)
      margin-bottom: 14px

      +media-screen-1280x800
        margin-bottom: 12px

      +media-screen-1024x768
        margin-bottom: 8px

      +media-screen-1024x600
        margin-bottom: 8px

    &:last-child
      margin-top: auto


  // $Item condiments control
  // $Item condiments action
  &__condiments-control,
  &__condiments-action
    flex-grow: 0
    flex-shrink: 0
    position: relative
    display: block
    width: calc(25% - 11px)
    height: 68px
    margin: 0
    padding: 0
    text-align: center
    border: none
    box-shadow: none
    border-radius: 12px
    cursor: pointer
    user-select: none
    overflow: hidden
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      border-radius: 9px

    +media-screen-1024x768
      width: calc(25% - 6px)
      height: 52px
      border-radius: 9px

    +media-screen-1024x600
      width: calc(25% - 6px)
      height: 42px
      border-radius: 7px

    &:before
      content: ''
      display: inline-block
      font-family: icons
      font-size: 42px
      line-height: 42px
      color: $c-item-condiments-control-icon

      +media-screen-1280x800
        font-size: 30px
        line-height: 30px

      +media-screen-1024x768
        font-size: 30px
        line-height: 30px

      +media-screen-1024x600
        font-size: 22px
        line-height: 22px

    &:hover
      background-color: $c-item-condiments-control-hover-bg

      &:before
        color: $c-item-condiments-control-hover-icon

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 14px

      +media-screen-1024x768
        margin-right: 8px

      +media-screen-1024x600
        margin-right: 8px

    // $Item condiments control STATE: disabled
    // $Item condiments action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Item condiments control MOD: role previous
    &_role_previous
      &:before
        content: '\e901'

    // $Item condiments control MOD: role  next
    &_role_next
      margin-left: auto

      &:before
        content: '\e902'

    // $Item modifier STATE: active
    &.is-active
      background-color: $c-item-modifier-active-bg

      &:hover
        opacity: 1

  // $Item condiments control
  &__condiments-control
    font-size: 0
    line-height: 0
    color: transparent
    background-color: $c-item-condiments-control-bg
    border: 5px solid $c-item-condiments-control-border

    +media-screen-1280x800
      border-width: 4px

    +media-screen-1024x768
      border-width: 4px

    +media-screen-1024x600
      border-width: 4px


  // $Item condiments actions
  &__condiments-action
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-item-condiments-action-text
    white-space: nowrap
    background-color: $c-item-condiments-action-bg

    +media-screen-1280x800
      font-size: 18px
      line-height: 21px

    +media-screen-1024x768
      font-size: 18px
      line-height: 21px

    +media-screen-1024x600
      font-size: 16px
      line-height: 18px

    &:hover
      background-color: $c-item-condiments-action-hover-bg

    // $Item condiments action MOD: role take off
    &_role_take-off
      background-color: $c-item-condiments-action-take-off-bg

      &:hover
        background-color: $c-item-condiments-action-take-off-hover-bg

    // $Item condiments action MOD: role add on
    &_role_add-on
      margin-left: auto
      background-color: $c-item-condiments-action-add-on-bg

      &:hover
        background-color: $c-item-condiments-action-add-on-hover-bg


  // $Item condiment
  &__condiment
    flex-grow: 0
    flex-shrink: 0
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    width: calc(25% - 11px)
    height: 68px
    padding: 6px 4px
    border-radius: 12px
    background-color: $c-item-condiment-bg
    cursor: pointer
    user-select: none
    text-decoration: none
    overflow: hidden
    transition: all .2s

    +media-screen-1280x800
      height: 58px
      padding: 4px
      border-radius: 9px

    +media-screen-1024x768
      width: calc(25% - 6px)
      height: 52px
      padding: 4px 2px
      border-radius: 9px

    +media-screen-1024x600
      width: calc(25% - 6px)
      height: 42px
      padding: 2px
      border-radius: 7px

    &:hover
      background-color: $c-item-condiment-hover-bg

    &:focus
      outline: none

    &:not(:last-child)
      margin-right: 14px

      +media-screen-1024x768
        margin-right: 8px

      +media-screen-1024x600
        margin-right: 8px

    // $Item condiment STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none

    // $Item modifier STATE: active
    &.is-active
      background-color: $c-item-condiment-active-bg

      &:hover
        opacity: 1

    // $Item condiment MOD: role take off
    &_role_take-off
      background-color: $c-item-condiment-take-off-bg

    // $Item condiment MOD: role take off STATE: Active
    &_role_take-off.is-active
      background-color: $c-item-condiment-take-off-active-bg

    // $Item condiment MOD: role add on
    &_role_add-on
      background-color: $c-item-condiment-add-on-bg

    // $Item condiment MOD: role add on STATE: active
    &_role_add-on.is-active
      background-color: $c-item-condiment-add-on-active-bg


  // $Item condiment text
  &__condiment-text
    display: block
    margin-bottom: 4px
    font-family: $f-sans
    font-size: 19px
    line-height: 1
    font-weight: 700
    color: $c-item-condiment-text
    text-transform: uppercase
    text-align: center

    +media-screen-1280x800
      margin-bottom: 3px
      font-size: 17px

    +media-screen-1024x768
      margin-bottom: 2px
      font-size: 15px

    +media-screen-1024x600
      margin-bottom: 1px
      font-size: 13px


  // $Item condiment price
  &__condiment-price
    display: block
    font-family: $f-sans
    font-size: 17px
    line-height: 1
    font-weight: 700
    color: $c-item-condiment-price-text
    text-align: center

    +media-screen-1280x800
      font-size: 15px

    +media-screen-1024x768
      font-size: 14px

    +media-screen-1024x600
      font-size: 12px


  // $Item quantity
  &__quantity
    display: block
    width: 100%
    margin-bottom: 10px

    +media-screen-1280x800
      margin-bottom: 9px

    +media-screen-1024x768
      margin-bottom: 8px

    +media-screen-1024x600
      margin-bottom: 6px


  // $Item quantity label
  &__quantity-label
    display: block
    width: 100%
    font-family: $f-sans
    font-size: 21px
    line-height: 25px
    font-weight: 700
    color: $c-item-quantity-label-text
    text-align: center
    margin-bottom: 5px

    +media-screen-1280x800
      margin-bottom: 3px
      font-size: 18px
      line-height: 21px

    +media-screen-1024x768
      margin-bottom: 3px
      font-size: 18px
      line-height: 21px

    +media-screen-1024x600
      margin-bottom: 2px
      font-size: 15px
      line-height: 17px


  // $Item actions
  &__actions
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%
    padding-top: 26px

    +media-screen-1280x800
      padding-top: 18px

    +media-screen-1024x768
      padding-top: 18px

    +media-screen-1024x600
      padding-top: 14px


  // $Item action
  &__action
    display: block
    width: 265px
    height: 70px
    margin: 0
    padding: 0 5px
    border: none
    box-shadow: none
    font-family: $f-sans
    font-size: 37px
    line-height: 70px
    font-weight: 700
    color: $c-item-action-text
    text-align: center
    text-transform: uppercase
    border-radius: 12px
    background-color: $c-item-action-bg
    cursor: pointer
    user-select: none
    transition: all .2s

    +media-screen-1280x800
      height: 50px
      font-size: 31px
      line-height: 50px
      border-radius: 9px

    +media-screen-1024x768
      width: 212px
      height: 50px
      font-size: 27px
      line-height: 50px
      border-radius: 9px

    +media-screen-1024x600
      width: 199px
      height: 40px
      font-size: 24px
      line-height: 40px
      border-radius: 7px

    &:hover
      background-color: $c-item-action-hover-bg

    &:focus
      outline: none

    // $Item action STATE: disabled
    &:disabled,
    &[disabled],
    &.is-disabled
      cursor: default
      opacity: .4
      pointer-events: none
